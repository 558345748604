.pagination-endless {
  &__nav {

  }

  &__button {
    padding: 0 68px;
  }
}

@include respond-up('large') {
  .pagination-endless{
    &__button {
      padding: 0 68px;
    }
  }
}

@include respond('medium') {
  .pagination-endless{
    &__button {
      padding: 0 47px;
      height: 59px;
      line-height: 59px;
    }
  }
}

@include respond-down('small') {
  .pagination-endless{
    &__button {
      padding: 0 47px;
      height: 53px;
      line-height: 53px;
    }
  }
}