.like-show {
  display: flex;
  flex-direction: column;

  &__caption {
    letter-spacing: 1px;
    line-height: 42px;
  }

  &__caption-city {
    color: $primary-color;
    text-decoration: none;
  }

  &__text {
    color: rgba(0,0,0,0.56);
  }

  &__streak {
    display: block;
    text-decoration: none;
    color: inherit;

    background-color: $grey-color;
    border-left: 4px solid $secondary-color;
  }

  &__date {
    color: $primary-color;
    font-family: $h-font;
    display: block;
  }

  &__address {
    display: block;
  }
}

@include respond-up('large') {
  .like-show {
    &__caption {
      padding-bottom: 56px;
      max-width: 632px;
    }

    &__subtitle {
      padding-bottom: 30px;
      font-size: 25px;
      max-width: 392px;
    }

    &__caption-city {
      font-size: 25px;
    }

    &__text {
      max-width: 460px;
      font-size: 16px;
    }

    &__streak {
      max-width: 452px;
      margin-top: 72px;
      padding: 30px 40px 30px 34px;
    }

    &__date {
      font-size: 22px;
      padding-bottom: 13px;
      font-family: $h-font;
    }

    &__address {
      font-size: 20px;
    }

    &__form {
      padding-top: 92px;
      max-width: 552px;
    }
  }
}

@include respond('medium') {
  .like-show {
    justify-content: space-between;
    height: 100%;
    &__wrapper {
      padding-bottom: 30px;
    }

    &__caption {
      padding-bottom: 29px;
      max-width: 415px;
    }

    &__subtitle {
      padding-bottom: 30px;
      font-size: 23px;
      max-width: 392px;
    }

    &__caption-city {
      font-size: 23px;
    }

    &__text {
      max-width: 365px;
      font-size: 16px;
    }

    &__streak {
      padding: 28px 40px 29px 34px;
      max-width: 456px;
    }

    &__date {
      font-size: 19px;
      padding-bottom: 10px;
    }

    &__address {
      font-size: 18px;
    }

    &__form {
      padding-top: 40px;
      max-width: 293px;
    }
  }
}

@include respond-down('small') {
  .like-show {
    flex-direction: column;
    &__wrapper {

    }

    &__caption {
      padding-bottom: 30px;
    }

    &__subtitle {
      padding-bottom: 30px;
      font-size: 20px;
    }

    &__caption-city {
      font-size: 20px;
    }

    &__text {
      font-size: 16px;
    }

    &__streak {
      margin-top: 35px;
      padding: 21px 30px 18px 21px;
    }

    &__date {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: $h-font;
    }

    &__address {
      font-size: 14px;
    }

    &__form {
      padding-top: 30px;
    }
  }
}
