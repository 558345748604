.action-list {
  &__item {
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .action-list {
    &__items {
      padding-bottom: 130px;
    }

    &__item {
      margin-bottom: 40px;
      height: 340px;
    }
  }
}

@include respond('medium') {
  .action-list {
    &__items {
      padding-bottom: 75px;
    }

    &__item {
      margin-bottom: 30px;
      height: 260px;
    }
  }
}

@include respond-down('small') {
  .action-list {
    &__items {
      padding-bottom: 50px;
    }

    &__item {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
}