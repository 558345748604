.faq {
  background-color: #FFFFFF;
  &__list-questions {
    display: flex;
    flex-direction: column;
  }

  &__question {
    flex: 1 1 100%;
    border-top: 1px solid #E3E3E3;
    &:last-child {
      border-bottom: 1px solid #E3E3E3;
    }

    &_opened {
      .faq__answer-container {
        display: flex;
      }

      .faq__circle-content {
        display: none;
        &_opened {
          display: block;
        }
      }
    }
  }

  &__question-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  &__question-line {
    display: flex;
    align-items: center;
    color: #000;
  }

  &__circle {
    flex: 0 0 auto;
    background-color: $secondary-color;
    border-radius: 100px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #FFFFFF;
  }

  &__circle-content {
    padding-bottom: 2px;
    &_opened {
      display: none;
      padding-bottom: 2px;
    }
  }

  &__answer-container {
    display: none;
  }

  &__answer-text {
    color: rgba(0,0,0,0.56);
  }

  &__form-container {
    background-color: $grey-color;
  }

  &__form-wrapper {
    padding-bottom: 114px;
  }

  &__form-title {
    display: block;
  }

  &__form-content {
    display: flex;
    align-items: stretch;
  }

  &__form-united {
    display: flex;
  }

  &__form-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    background-color: $secondary-color;
  }

  &__conditions {
    font-size: 12px;
    color: rgba(0,0,0,0.35);
    &_primary {
      text-decoration: none;
      color: rgba(0,0,0,0.35);
      border-bottom: 1px solid rgba(0,0,0,0.35);
    }
  }
}

@include respond-up('large') {
  .faq {
    background: {
      image: url('../images/lace.png');
      position: top right;
      repeat: no-repeat;
      size: 338px 320px;
    };
    &__list-questions {
      margin-right: 207px;
      padding-top: 91px;
      padding-bottom: 112px;
    }

    &__question-link {
      padding: 34px 0;
    }

    &__question-line {
      font-size: 22px;
    }

    &__question-title {
      padding-left: 22px;
    }

    &__answer-text {
      font-size: 18px;
      padding-left: 47px;
      padding-bottom: 37px;
      padding-right: 60px;
    }

    &__button {
      padding: 0 82px;
    }

    &__button-wrapper {
      padding-left: 60px;
    }

    &__form-title {
      padding-top: 106px;
      padding-bottom: 60px;
    }

    &__form-content {
      display: flex;
      align-items: stretch;
    }

    &__form-united {
      flex: 0 0 30%;
      flex-direction: column;
      margin: -15px 0;
    }

    &__form-one {
      padding-left: 30px;
      flex: 0 0 70%;
    }

    &__form-bottom {
      padding-top: 24px;
    }
  }
}

@include respond('medium') {
  .faq {
    &__list-questions {
      padding-top: 63px;
      padding-bottom: 75px;
    }

    &__question-link {
      padding: 32px 0;
    }

    &__question-line {
      font-size: 20px;
    }

    &__question-title {
      padding-left: 22px;
    }

    &__answer-text {
      font-size: 17px;
      padding-left: 47px;
      padding-bottom: 37px;
      padding-right: 50px;
    }

    &__form-title {
      padding-top: 70px;
      padding-bottom: 45px;
    }

    &__form-content {
      flex-direction: column;
    }

    &__form-united {
      flex: 0 0 100%;
      position: relative;
      display: flex;
      margin-bottom: 30px;
    }

    &__form-name {
      flex: 0 0 48%;
      margin-right: 30px;
      input[type="text"]{
        margin: 0;
      }
      ul {
        padding-left: 15px;
      }
    }

    &__form-link {
      flex: 0 0 48%;
      input[type="text"]{
        margin: 0;
      }
      ul {
        padding-left: 15px;
      }
    }

    &__form-one {
      flex: 0 0 100%;
    }

    &__form-bottom {
      padding-top: 30px;
      justify-content: space-between;
    }

    &__button {
      padding: 0 82px;
      line-height: 59px;
      height: 59px;
    }

    &__button-scroll {
      line-height: 59px;
      height: 59px;
    }

    &__conditions {
      max-width: 390px;
      &_primary {

      }
    }
  }
}

@include respond-down('small') {
  .faq {
    &__list-questions {
      padding-top: 39px;
      padding-bottom: 50px;
    }

    &__question-link {
      padding: 15px 0;
    }

    &__question-line {
      font-size: 16px;
    }

    &__question-title {
      padding-left: 14px;
    }

    &__answer-text {
      font-size: 15px;
      padding-left: 38px;
      padding-bottom: 37px;
      padding-right: 47px;
    }

    &__form-title {
      padding-top: 42px;
      padding-bottom: 32px;
    }

    &__form-content {
      flex-direction: column;
    }

    &__form-united {
      flex: 0 0 100%;
      flex-direction: column;
    }

    &__form-one {
      flex: 0 0 100%;
    }

    &__form-bottom {
      padding-top: 15px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__form {
      input[type="text"]{
        height: 53px;
        line-height: 53px;
      }
    }

    &__button-wrapper {
      flex: 0 0 100%;
      padding-bottom: 17px;
      width: 100%;
    }

    &__button {
      display: flex;
      align-items: center;
      padding: 0 82px;
      width: 100%;
      justify-content: center;
      height: 53px;
      line-height: 53px;
    }

    &__button-scroll {
      height: 53px;
      line-height: 53px;
    }

    &__conditions {
      flex: 0 0 100%;
      font-size: 12px;
      color: rgba(0,0,0,0.35);
      &_primary {
        text-decoration: none;
        color: rgba(0,0,0,0.35);
        border-bottom: 1px solid rgba(0,0,0,0.35);
      }
    }
  }
}