.slide-nav {
  display: flex;
  align-items: stretch;
  background-color: $primary-color;
  justify-content: space-between;

  &__hand-link-left, &__hand-link-right, &__number {
    display: flex;
    align-items: center;
  }

  &__number {
    line-height: 1.6;
    color: rgba(255,255,255,0.46);
    letter-spacing: 0.9px;
  }

  &__current {

  }

  &__all {

  }

  &__hand-link-right {

  }
}

@include respond-up('large') {
  .slide-nav {
    height: 46px;
    width: 145px;

    &__hand-link-left {
      padding-left: 30px;
    }

    &__hand-link-right {
      padding-right: 30px;
    }

    &__number {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .slide-nav {
    &__hand-link-left {
      padding: 15px 0 15px 30px;
    }

    &__hand-link-right {
      padding: 15px 30px 15px 0;
    }

    &__number {
      padding: 6px 15px 0;
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .slide-nav {
    &__hand-link-left {
      padding: 13px 0 13px 23px;
    }

    &__hand-link-right {
      padding: 13px 23px 13px 0;
    }

    &__number {
      padding: 6px 15px 0;
      font-size: 18px;
    }
  }
}