.title {
  font-family: $h-font;
  text-transform: uppercase;

  &_big {
    font-size: 34px;
  }

  &_small {
    font-size: 24px;
  }
}

@include respond-up('large') {
  .title {
    font-size: 35px;

    &_medium {
      font-size: 25px;
    }
  }
}

@include respond('medium') {
  .title {
    font-size: 27px;

    &_medium {
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .title {
    font-size: 21px;

    &_medium {
      font-size: 17px;
    }
  }
}