.cities {
  &__form {
    padding: 120px;
  }

  &__title {
    font-size: 21px;
    padding-bottom: 20px;
    text-align: center;
  }

  &__link {
    text-decoration: none;
    color: $primary-color;
  }

  &__if {
    padding-bottom: 40px;
    color: rgba(0,0,0,0.56);
    text-align: center;
  }

  &__field {
    .form-field .choices:after {
      border-color: #C1C6CD transparent transparent transparent;
      right: 21px;
      top: 32px;
    }

    .form-field .choices.is-open:after {
      border-color: transparent transparent #C1C6CD transparent;
      right: 21px;
      top: 32px;
    }

    .is-open {
      &::before {
        content: '';
        position: absolute;
        top: 50px;
        left: 50px;
        border: 16px solid transparent;
        border-bottom: 16px solid #fff;
        z-index: 100;
      }
    }

    .choices,
    .is-open,
    .is-focused {
      .choices__inner {
        height: 60px;
        line-height: 20px;
        background: $grey-color;
        border: 1px solid #E6E8EB;
        border-radius: 100px;
        padding: 16px;
        margin-bottom: 20px;

        .choices__list {
          background: inherit;
          box-shadow: inherit;

          .choices__item {
            font-size: 16px;
            background: inherit;
            box-shadow: inherit;
          }
        }
      }

      .choices__list,
      .choices__list--dropdown {
        background: #FFFFFF;
        border-radius: 0;
        border: none;

        .choices__list {
          padding: 10px 0;
        }

        .choices__item {
          background-color: #fff;
          font-size: 15px;
          padding-right: 10px;

          &:hover {
            background: #F2F3F4;
          }
        }

        input {
          background: #fff;
          border-radius: 0;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #ddd;
          height: 40px;
          z-index: 10;
        }
      }

      .choices__list--dropdown {
        box-shadow: 0 -2px 50px 0 rgba(0, 0, 0, 0.11);

        .choices__list {
          max-height: 135px;
        }
      }

      .is-highlighted:after {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__button {
    height: 60px;
    line-height: 60px;
    font-weight: bold;
  }
}

@include respond-down('small') {
  .cities {
    &__title {
      font-size: 16px;
    }

    &__link {
      display: flex;
      justify-content: center;
    }

    &__form {
      padding: 30px 0;
    }
  }
}