.reviews {
  background-color: #FFFFFF;

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-review {
    z-index: 10;
  }

  &__form-container {
    background-color: $grey-color;
  }

  &__form-wrapper {
    padding-bottom: 114px;
  }

  &__form-title {
    display: block;
  }

  &__form-content {
    //display: flex;
    //align-items: stretch;
  }

  &__form-united {
    display: flex;
  }

  &__form-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    background-color: $secondary-color;
  }

  &__conditions {
    font-size: 12px;
    color: rgba(0,0,0,0.35);
    &_primary {
      text-decoration: none;
      color: rgba(0,0,0,0.35);
      border-bottom: 1px solid rgba(0,0,0,0.35);
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
  }

  &__button-all {
    display: flex;
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(81,101,214,0.25);
    text-decoration: none;
  }

  &__button-text {
    font-weight: bold;
    color: #5165D6;
  }

  &__title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include respond('large') {
  .reviews {
    background: {
      image: url('../images/lace.png');
      position: top right;
      repeat: no-repeat;
      size: 338px 320px;
    };

    &_none-bg {
      background-image: none;
    }

    &__wrapper {
      background: {
        image: url('../images/lace-bottom.png');
        position: bottom left;
        repeat: no-repeat;
        size: 423px 400px;
      };

      &_none-bg {
        background-image: none;
      }

      &_none-bg {
        background-image: none;
      }
    }

    &__items {
      margin: -50px 0;
      padding-top: 90px;
      padding-bottom: 93px;
    }

    &__item {
      padding: 50px 0;
    }

    &__pager {
      padding-bottom: 104px;
    }

    &__button {
      padding: 0 82px;
    }

    &__button-wrapper {
      padding-left: 60px;
    }

    &__form-title {
      padding-top: 106px;
      padding-bottom: 60px;
    }

    &__form-content {
      display: flex;
      align-items: stretch;
    }

    &__form-united {
      flex: 0 0 30%;
      flex-direction: column;
      margin: -15px 0;
    }

    &__form-one {
      padding-left: 30px;
      flex: 0 0 70%;
    }

    &__form-bottom {
      padding-top: 24px;
    }

    &__button-all {
      margin-left: 62px;
      padding-bottom: 7px;
    }

    &__button-icon {
      padding-right: 12px;
    }

    &__button-text {
      font-size: 16px;
    }

    &__title-line {
      padding: 135px 0 0 0;
    }

    &__button-review {
      padding: 0 58px;
    }
  }

  .reviews-index {
    background-image: none;

    .reviews {
      &__wrapper {
        background: {
          image: url('../images/lace-bottom-right.png');
          position: bottom right;
          repeat: no-repeat;
          size: 472px 446px;
        };
      }
    }
  }
}

@include respond('medium') {
  .reviews {
    &__items {
      margin: -36px 0;
      padding: 65px 0;
    }

    &__item {
      padding: 36px 0;
    }

    &__pager {
      padding-bottom: 75px;
    }

    &__form-title {
      padding-top: 70px;
      padding-bottom: 45px;
    }

    &__form-content {
      flex-direction: column;
    }

    &__form-united {
      flex: 0 0 100%;
      position: relative;
      display: flex;
      margin-bottom: 30px;
    }

    &__form-name {
      flex: 0 0 48%;
      margin-right: 30px;
      input[type="text"]{
        margin: 0;
      }
      ul {
        padding-left: 15px;
      }
    }

    &__form-link {
      flex: 0 0 48%;
      input[type="text"]{
        margin: 0;
      }
      ul {
        padding-left: 15px;
      }
    }

    &__form-one {
      flex: 0 0 100%;

      textarea {
        min-height: 160px;
        min-width: 100%;
      }
    }

    &__form-bottom {
      padding-top: 30px;
      justify-content: space-between;
    }

    &__button {
      padding: 0 82px;
    }

    &__conditions {
      max-width: 390px;
      &_primary {

      }
    }

    &__button-all {
      margin-left: 39px;
      padding-bottom: 7px;
    }

    &__button-icon {
      padding-right: 12px;
    }

    &__button-text {
      font-size: 16px;
    }

    &__title-line {
      padding: 66px 0 0 0;
    }

    &__button-review {
      padding: 0 39px;
      height: 59px;
      line-height: 59px;
    }
  }

  .reviews-index {
    background-image: none;

    .reviews {
      &__wrapper {
        background-image: none !important;
      }
    }
  }
}

@include respond-down('small') {
  .reviews {
    &__items {
      margin: -29px 0;
      padding-top: 36px;
      padding-bottom: 43px;
    }

    &__item {
      padding: 29px 0;
      border-bottom: 1px solid $grey-color;
      &:last-child {
        border: none;
      }
    }

    &__button-container {
      flex-direction: column;
    }

    &__button-all {
      margin-top: 22px;
      padding-bottom: 7px;
    }

    &__button-icon {
      padding-right: 12px;
    }

    &__button-text {
      font-size: 15px;
    }

    &__pager {
      padding-bottom: 50px;
    }

    &__form-title {
      padding-top: 42px;
      padding-bottom: 32px;
    }

    &__form-content {
      flex-direction: column;
    }

    &__form-united {
      flex: 0 0 100%;
      flex-direction: column;
    }

    &__form-one {
      flex: 0 0 100%;

      textarea {
        min-height: 100px;
        min-width: 100%;
      }
    }

    &__form {
      input[type="text"]{
        height: 53px;
        line-height: 53px;
      }
    }

    &__form-bottom {
      padding-top: 15px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__button-wrapper {
      flex: 0 0 100%;
      padding-bottom: 17px;
      width: 100%;
    }

    &__button {
      display: flex;
      align-items: center;
      padding: 0 82px;
      width: 100%;
      height: 53px;
      line-height: 53px;
      justify-content: center;
    }

    &__conditions {
      flex: 0 0 100%;
      font-size: 12px;
      color: rgba(0,0,0,0.35);
      &_primary {
        text-decoration: none;
        color: rgba(0,0,0,0.35);
        border-bottom: 1px solid rgba(0,0,0,0.35);
      }
    }

    &__title-line {
      flex-direction: column;
      padding: 41px 0 0 0;
    }

    &__caption {
      padding-bottom: 30px;
    }

    &__button-review {
      padding: 0 39px;
      height: 53px;
      line-height: 53px;
    }
  }

  .reviews-index {
    background-image: none;

    .reviews {
      &__wrapper {
        background-image: none !important;
      }

      &__title-line {
        align-items: flex-start;
      }
    }
  }
}