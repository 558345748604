.counter {
  &__value {
    line-height: 1;
    color: #FF6651;
  }
}

@include respond-up('large') {
  .counter {
    flex: 0 0 50%;
    margin-bottom: 25px;
    padding-right: 60px;

    &_home {
      flex: 0 0 25%;
    }

    &__value {
      font-size: 55px;
      padding-bottom: 8px;
    }

    &__name {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .counter {
    flex: 0 0 25%;

    &__value {
      padding-bottom: 12px;
    }

    &__name {
      max-width: 166px;
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .counter {
    &__value {
      font-size: 45px;
    }
  }
}

@include respond-down('small') {
  .counter {
    flex: 0 0 50%;
    padding-top: 16px;

    &__value {
      padding-bottom: 10px;
    }

    &__name {
      max-width: 140px;
      padding-right: 20px;
      font-size: 15px;
    }
  }
}

@include respond-down('x-small') {
  .counter {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__name {
      max-width: 200px;
      padding-right: 0;
    }
  }
}