.content-header {
  padding: 55px 0;

  &__main {

  }

  &__heading {

  }

  &__additional {
    display: none;
  }

  &_has-additional {
    .content-header {
      &__additional {
        display: block;
      }
    }
  }
}

@include respond-up('large') {
  .content-header {
    &_has-additional {
      .content-header {
        &__container {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .content-header {
    &_additional-bottom {
      .content-header {
        &__container {
          align-items: flex-end;
        }
      }
    }
  }
}

@include respond('medium') {
  .content-header {
    padding-top: 54px;
    padding-bottom: 42px;

    &_additional-row {
      .content-header {
        &__container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &_additional-column {
      .content-header {
        &__additional {
          padding-top: 35px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .content-header {
    padding-top: 22px;
    padding-bottom: 26px;

    &_has-additional {
      .content-header {
        &__additional {
          padding-top: 25px;
        }
      }
    }
  }
}