@font-face {
    font-family: 'AGGaramond Cyr';
    src: url('../fonts/AGGaramondCyrLight.eot');
    src: url('../fonts/AGGaramondCyrLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AGGaramondCyrLight.woff2') format('woff2'),
    url('../fonts/AGGaramondCyrLight.woff') format('woff'),
    url('../fonts/AGGaramondCyrLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AGGaramond Cyr';
    src: url('../fonts/AGGaramondCyrLight.eot');
    src: url('../fonts/AGGaramondCyrLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AGGaramondCyrLight.woff2') format('woff2'),
    url('../fonts/AGGaramondCyrLight.woff') format('woff'),
    url('../fonts/AGGaramondCyrLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AGGaramond Cyr';
    src: url('../fonts/AGGaramondCyrBold.eot');
    src: url('../fonts/AGGaramondCyrBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AGGaramondCyrBold.woff2') format('woff2'),
    url('../fonts/AGGaramondCyrBold.woff') format('woff'),
    url('../fonts/AGGaramondCyrBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
