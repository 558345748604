.hero {
  &__wrap {
    position: relative;
    transition-property: opacity, transform;
    transition-duration: 1.3s;
    transition-delay: 1s;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.auto-animated {
      opacity: 0;
      transform: translateY(30px);
      transition-property: none;
      transition-delay: 0s;
    }
  }

  &__items {

  }

  &__item {

  }

  &__main {

  }

  &__image {
    display: block;
  }

  &__img {
    display: block;
  }

  &__info {

  }

  &__link {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__banner {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__nav {
    position: absolute;
    right: 0;
    bottom: 0;

    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .6s;
    transition-delay: 1.8s;

    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateX(40px);
      transition-delay: 0s;
    }
  }
}

@include respond-up('large') {
  .hero {
    background-color: #fff;

    &__wrap,
    &__main,
    &__banner {
      height: 568px; // ну потому что да
    }

    &__wrap {
      width: 1200px;
      margin: 0 auto;
    }

    &__main {
      display: flex;
      margin: 0 -10px;
    }

    &__image,
    &__info {
      flex: 0 0 50%;
      padding: 0 10px;
    }

    &__image {
      align-self: flex-end;
    }

    &__info {
      margin: 70px 0 40px;
    }

    &__banner {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__wrap {
      background-image: radial-gradient(53% 80%, #E0E5E8 42%, #ACB1B8 120%);
    }
  }
}

@include respond('medium') {
  .hero {
    &__main {
      display: flex;
      flex-direction: column-reverse;
      padding: 0 24px;
    }

    &__info {
      margin: 45px 60px 10px 0;
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__img {
      width: 100%;
    }

    &__banner {
      &_desktop {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .hero {
    &__image {
      background-image: radial-gradient(53% 80%, #E0E5E8 42%, #ACB1B8 120%);
    }

    &__info {
      margin: 28px 20px 54px;
    }

    &__nav {
      transform: translateY(50%);
    }
  }
}