.footer {
  background-color: $dark-color;

  &__main {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    margin-right: 10px;
  }

  &__number {
    font-size: 23px;
    font-weight: bold;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 14px;
  }

  &__link {
    display: flex;
    text-decoration: none;
    color: #fff;

    &_dark {
      color: #7C8188;
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    padding: 14px 0;
  }

  &__info {
    display: flex;
    font-size: 14px;
  }

  &__info-list {
    display: flex;
  }
}

@include respond-up('large'){
  .footer {
    &__main {
      padding: 134px 0;
    }

    &__list {
      &:first-child {
        margin-right: 50px;
      }
    }

    &__link {
      &_phone {
        padding-bottom: 20px;
      }
    }

    &__info {
      flex: 0 0 100%;
      justify-content: space-between;
      padding-top: 86px;
    }

    &__info-item {
      padding-right: 30px;
    }
  }
}

@include respond-up('medium'){
  .footer {
    &__main {
      justify-content: space-between;
    }

    &__link {
      &_address {
        padding-bottom: 36px;
      }
    }

    &__social {
      margin-bottom: -1px;
      border: 1px solid rgba(255,255,255,0.19);
      border-bottom: none;
    }
  }
}

@include respond('medium'){
  .footer {
    &__contacts {
      flex: 0 0 55%;
    }

    &__menu,
    &__info {
      flex: 0 0 45%;
    }

    &__social {
      margin-top: -22px;
    }

    &__info-list {
      flex: 0 0 60%;
      justify-content: flex-end;
    }

    &__r-class {
      flex: 0 0 40%;
      display: flex;
      align-items: flex-end;
    }
  }
}

@include respond-down('medium'){
  .footer {
    &__main {
      padding: 76px 0;
    }

    &__link {
      &_phone {
        padding-bottom: 10px;
      }
    }

    &__list {
      flex: 0 0 100%;
    }

    &__info {
      flex-direction: column;
    }

    &__info-list {
      flex-direction: column;
    }

    &__info-item {
      padding-top: 8px;
    }
  }
}

@include respond-down('small'){
  .footer {
    &__main {
      padding: 42px 0 50px;
    }

    &__link {
      &_address {
        padding-bottom: 30px;
      }
    }

    &__contacts,
    &__menu,
    &__info {
      flex: 0 0 100%;
    }

    &__contacts {
      padding-bottom: 40px;
    }

    &__menu {
      padding-bottom: 8px;
    }

    &__socials {
      padding-bottom: 18px;
    }

    &__vk {
      display: none;
    }

    &__r-class {
      padding-top: 36px;
    }
  }
}