.placard {
  height: 100%;

  &__container {
    height: 100%;
  }

  &__show {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__caption {
    font-family: $h-font;
    letter-spacing: 0.86px;
    line-height: 40px;
    text-transform: uppercase;
  }

  &__title-link {
    color: $primary-color;
    text-decoration: none;
  }

  &__text {
    color: rgba(0,0,0,0.56);
    letter-spacing: 0;
    line-height: 24px;
  }

  &__streak {
    background-color: $grey-color;
    border-left: 4px solid $secondary-color;
    display: block;
    text-decoration: none;
    color: inherit;
  }

  &__date {
    color: $primary-color;
    font-family: $h-font;
    display: block;
  }
}

@include respond-up('large') {
  .placard {
    background-size: cover;
    background-repeat: no-repeat;

    &__container {
      background-size: contain;
      background-position-x: 100%;
      background-repeat: no-repeat;
    }

    &__show {
      padding: 61px 0 66px 69px;
      justify-content: space-between;
    }

    &__caption {
      font-size: 30px;
      max-width: 632px;
      padding-bottom: 29px;
    }

    &__text {
      font-size: 16px;
      max-width: 486px;
    }

    &__streak {
      max-width: 452px;
      margin-top: 51px;
      padding: 30px 40px 28px 34px;
    }

    &__date {
      font-size: 20px;
      padding-bottom: 9px;
      font-family: $h-font;
    }

    &__address {
      font-size: 18px;
    }

    &__form {
      max-width: 533px;
    }
  }
}

@include respond('medium') {
  .placard {
    background-size: cover;
    background-repeat: no-repeat;

    &__container {
      background-image: none !important;
    }

    &__show {
      padding: 52px 36px 62px 38px;
    }

    &__caption {
      font-size: 21px;
      letter-spacing: 0.6px;
      line-height: 28px;
      padding-bottom: 22px;
    }

    &__text {
      font-size: 15px;
    }

    &__streak {
      max-width: 379px;
      margin-top: 39px;
      padding: 21px 20px 16px 25px;
    }

    &__date {
      font-size: 17px;
      padding-bottom: 5px;
      font-family: $h-font;
    }

    &__address {
      font-size: 16px;
    }

    &__form {
      margin-top: 40px;
      .request-form__data {
        margin-right: 0;
      }
    }
  }
}

@include respond-down('small') {
  .placard {
    background-size: cover;
    background-repeat: no-repeat;
    &__container {
      background-image: none !important;
    }

    &__show {
      padding: 27px 25px;
    }

    &__caption {
      font-size: 21px;
      color: #000000;
      letter-spacing: 0.6px;
      line-height: 28px;
      padding-bottom: 19px;
    }

    &__text {
      font-size: 15px;
    }

    &__streak {
      max-width: 452px;
      margin-top: 39px;
      padding: 21px 20px 21px 19px;
    }

    &__date {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: $h-font;
    }

    &__address {
      font-size: 15px;
    }

    &__form {
      margin-top: 25px;
    }
  }
}