.catalog-header{
  display: flex;
  align-items: center;

  &__header{
    flex: 0 0 auto;
  }

  &__category-list{
    justify-content: flex-end;
    flex: 1 0 auto;
    display: flex;
    margin-right: -18px;
  }

  &__item{
    flex: 0 0 auto;
  }

  &__link{
    text-decoration: none;
    padding: 0 18px;
    border-radius: 100px;
    height: 43px;
    display: block;

    &_active{
      background-color: #FF6651;
      .catalog-header__name{
        color: white;
        border-bottom: none;
      }
    }
  }

  &__name{
    font-size: 17px;
    color: #5165D6;
    border-bottom: 1px solid #5165D6;
    line-height: 43px;
    white-space: nowrap;
  }
}

@include respond-down('medium'){
  .catalog-header{
    flex-wrap: wrap;
    &__header{
      flex: 0 0 100%;
    }
    &__category-list{
      flex: 0 0 100%;
      justify-content: flex-start;
      margin: 46px 0 0;
    }
  }
}

@include respond-down('small'){
  .catalog-header{
    &__category-list{
      flex-wrap: wrap;
    }

    &__item{
     margin-bottom: 10px;
    }

    &__link{
      height: 36px;
      padding: 0 12px;

    }
    &__name{
      font-size: 16px;
      line-height: 36px;
    }
  }
}