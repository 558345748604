.advantage {
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: .6s;

  &.need-animation{
    transition-property: none;
    opacity: 0;
    transform: translateY(20px);

    .advantage__item{
      opacity: 0;
      transform: translateY(20px);
    }
  }

  &__container {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: -40px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__item {
    display: flex;
    transition-property: opacity, transform;
    opacity: 1;
    transition-duration: .6s;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}){
        transition-delay: calc(#{$i} * 0.6s);
      }
    }
  }

  &__content {
    background-color: #FFFFFF;
    display: flex;
    text-decoration: none;
    color: inherit;
  }

  &__image {
    width: 162px;
    height: 162px;
    background: {
      size: 162px 162px;
      repeat: no-repeat;
    };
    flex: 0 0 auto;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__caption {
    background-color: $primary-color;
    color: #FFFFFF;
    line-height: 24px;
    display: block;
  }

  &__name {
    font-family: $h-font;
    letter-spacing: 0.6px;
    line-height: 29px;
    text-transform: uppercase;
    display: block;
  }

  &__description {
    border-left: 4px solid $secondary-color;
    color: rgba(0,0,0,0.67);
    line-height: 26px;
  }
}

@include respond-up('large') {
  .advantage {
    padding-bottom: 100px;
    &_row {
      .advantage {
        &__item {
          padding: 0 20px;
          flex: 0 0 33.333%;
        }

        &__content {
          flex-direction: column;
        }

        &__info {
          padding: 0;
        }

        &__caption {
          margin-top: 30px;
        }
      }
    }

    &__title {
      padding-top: 134px;
      padding-bottom: 94px;
    }

    &__list {
      flex-wrap: wrap;
      margin: 0 -20px;
    }

    &__item {
      flex: 0 0 50%;
      padding: 0 20px 40px;
    }

    &__content {
      padding: 35px 31px 48px 42px;
    }

    &__info {
      padding-left: 42px;
    }

    &__caption {
      margin-top: 14px;
      padding: 0 8px;
      font-size: 15px;
    }

    &__name {
      padding-top: 16px;
      font-size: 22px;
    }

    &__description {
      margin-top: 26px;
      padding-left: 19px;
      font-size: 17px;
    }
  }
}

@include respond('medium') {
  .advantage {
    padding-bottom: 75px;
    &__title {
      padding-top: 69px;
      padding-bottom: 58px;
    }

    &__container {
      margin: 0 -30px;
    }

    &__wrapper {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    &__list {
      flex-wrap: nowrap;
      width: 100%;
      padding-left: 20px;
      margin-left: 0 -30px;
    }

    &__item {
      padding: 0 15px;
      flex: 0 0 400px;
    }

    &__content {
      flex-direction: column;
      padding: 38px 30px 31px 37px;
    }

    &__image {
      margin-bottom: 30px;
    }

    &__caption {
      padding: 0 8px;
      font-size: 15px;
    }

    &__name {
      padding-top: 14px;
      font-size: 21px;
    }

    &__description {
      margin-top: 26px;
      padding-left: 19px;
      font-size: 17px;
    }
  }
}

@include respond-down('small') {
  .advantage {
    padding-bottom: 50px;
    &__title {
      padding-top: 40px;
      padding-bottom: 42px;
    }

    &__container {
      margin: 0 -20px;
    }

    &__wrapper {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    &__list {
      flex-wrap: nowrap;
      width: 100%;
      margin: 0 -10px 0 5px;
    }

    &__item {
      padding: 0 15px;
      flex: 0 0 300px;
    }

    &__content {
      flex-direction: column;
      padding: 28px;
    }

    &__image {
      margin-bottom: 26px;
    }

    &__caption {
      padding: 0 8px;
      font-size: 15px;
    }

    &__name {
      padding-top: 13px;
      font-size: 17px;
    }

    &__description {
      margin-top: 20px;
      padding-left: 16px;
      font-size: 15px;
    }
  }
}