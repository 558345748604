.events-index {
  transition-property: opacity, transform;
  transition-duration: 1.3s;
  transition-delay: 1s;
  opacity: 1;

  &.auto-animated{
    opacity: 0;
    transform: translateY(30px);
    transition-property: none;
    transition-delay: 0s;
  }
  &__list {
    position: relative;
  }

  &__slider{

  }

  &__item {

  }

  &__pagination{
    bottom: 0;
    right: 0;
    position: absolute;
    &_hide{
      display: none;
    }
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .6s;
    transition-delay: 2.5s;
    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateY(10px);
      transition-delay: 0s;
    }
  }
}