.review-modal {
  &__title {
    display: flex;
    justify-content: center;
  }

  &__united {
    display: flex;
    flex-wrap: wrap;
  }

  &__field {
    input[type="text"] {
      color: #000;
      background: #F2F3F4;
    }
  }

  &__text {
    textarea {
      color: #000;
      background: #F2F3F4;
    }
  }

  &__bottom {
    display: flex;
  }

  &__conditions {
    font-size: 12px;
    color: rgba(0,0,0,0.35);
  }

  &__link {
    text-decoration: none;
    color: rgba(0,0,0,0.35);
    border-bottom: 1px solid rgba(0,0,0,0.35);
  }

  &__button {
    width: 100%;
    background-color: $secondary-color;
  }
}

@include respond-up('medium') {
  .review-modal {
    &__form {
      padding: 60px 80px 70px;
    }

    &__title {
      font-size: 29px;
      padding-bottom: 30px;
    }

    &__field {
      flex: 0 0 50%;
      margin-bottom: 13px;

      &:first-child {
        padding-right: 15px;
      }

      &:last-child {
        padding-left: 15px;
      }
    }

    &__text {
      textarea {
        min-height: 160px;
      }
    }

    &__bottom {
      justify-content: space-between;
      align-items: center;
      padding-top: 30px;
    }

    &__conditions {
      padding-right: 50px;
    }

    &__button-wrapper {
      flex: 0 0 250px;
    }
  }
}


@include respond-down('small') {
  .review-modal {
    &__form {
      padding: 50px 0 60px;
    }

    &__title {
      font-size: 19px;
      padding-bottom: 25px;
    }

    &__field {
      flex: 0 0 100%;
    }

    &__text {
      textarea {
        min-height: 96px;
      }
    }

    &__bottom {
      flex-wrap: wrap;
      padding-top: 15px;
    }

    &__conditions {
      padding-top: 17px;
      text-align: center;
      order: 2;
    }

    &__button-wrapper {
      flex: 0 0 100%;
      order: 1;
    }
  }
}