.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0;
}

.magnifier-lens {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: hidden
}

.magnifier-loader {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px #ccc;
  color: #fff;
  text-align: center;
  background: transparent;
  background: rgba(50, 50, 50, 0.5);
  z-index: 1000;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F323232,endColorstr=#7F323232)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F323232,endColorstr=#7F323232)
}

.magnifier-loader-text {
  font: 13px Arial;
  margin-top: 10px
}

.magnifier-large {
  position: absolute;
  z-index: 100
}

.magnifier-preview {
  padding: 0;
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden
}

.magnifier-preview img {
  position: absolute;
  top: 0;
  left: 0
}

.opaque {

}

.hidden {
  display: none
}