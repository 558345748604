.offsite {
  &__info {
    border-left: 4px solid #FF6651;
  }

  &__top {
    background-color: #fff;
  }

  &__content {
    line-height: 1.5;
  }

  &__about {
    display: flex;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__bot {
    background-color: #fff;
  }
}

@include respond-up('large') {
  .offsite {
    &__event {
      max-width: 1200px;
      margin: 0 auto -150px;
    }

    &__top {
      padding: 260px 0 120px;
      background: {
        image: url("../images/lace-top-left.png");
        repeat: no-repeat;
        position: top left;
      };
    }

    &__content {
      padding-top: 60px;

      &_top {
        padding-right: 180px;
      }

      &_bot {
        padding-right: 50px;
        padding-left: 140px;
      }
    }

    &__image {
      &_top {
        flex: 0 0 35%;
        height: 440px;
      }

      &_bot {
        flex: 0 0 40%;
        height: 360px;
      }
    }

    &__bot {
      padding: 50px 0 135px;
    }

    &__advantage {
      &_mobile {
        display: none;
      }
    }

    &__buy {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .offsite {
    &__info {
      font-size: 22px;
      padding-left: 22px;
    }

    &__content {
      &_top {
        flex: 0 0 65%;
      }

      &_bot {
        flex: 0 0 60%;
      }
    }
  }
}

@include respond('medium') {
  .offsite {
    &__top {
      overflow: hidden;
      padding: 80px 0 86px;
    }

    &__bot {
      overflow: hidden;
      padding: 20px 0 75px;
    }

    &__content {
      padding-top: 12px;

      &_top {
        padding-right: 30px;
      }

      &_bot {
        padding-left: 30px;
      }
    }

    &__image {
      &_top {
        flex: 0 0 35%;
        position: relative;
        left: 34px;
        height: 410px;
      }

      &_bot {
        flex: 0 0 40%;
        position: relative;
        right: 34px;
        height: 360px;
      }
    }
  }
}

@include respond-down('medium') {
  .offsite {
    &__advantage {
      &_desktop {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .offsite {
    &__info {
      font-size: 17px;
      padding-left: 20px;
    }

    &__top {
      padding: 70px 0 50px;
    }

    &__bot {
      padding: 10px 0 50px;
    }

    &__about {
      flex-wrap: wrap;
    }

    &__content {
      padding-top: 40px;

      &_top {
        order: 2;
        padding-right: 30px;
      }

      &_bot {
        padding-left: 30px;
      }
    }

    &__image {
      flex: 0 0 100%;
    }
  }
}