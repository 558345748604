$grid-columns: 12;

$x-small-breakpoint: 0;
$small-breakpoint: 321;
$medium-breakpoint: 768;
$large-breakpoint: 1240;

$x-small-gutter: 20;
$small-gutter: 20;
$medium-gutter: 30;
$large-gutter: 40;

$x-small-spacer: 20;
$small-spacer: 20;
$medium-spacer: 34;

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    gutter: $x-small-gutter,
    spacer: $x-small-spacer
  ),
  small: (
    from: $small-breakpoint,
    to: ($medium-breakpoint - 1),
    gutter: $small-gutter,
    spacer: $small-spacer
  ),
  medium: (
    from: $medium-breakpoint,
    to: ($large-breakpoint - 1),
    gutter: $medium-gutter,
    spacer: $medium-spacer
  ),
  large: (
    from: $large-breakpoint,
    gutter: $large-gutter
  )
);

$max-row-width: $large-breakpoint;

$body-font-size: 16px;
$font: "AGGaramond Cyr", serif;
$h-font: "Geometria", sans-serif;

$t: transparent;

$primary-color: #5165D6;
$secondary-color: #FF6651;
$grey-color: #F2F3F4;


$bright-blue-color: #206FDE;
$border-color: $bright-blue-color;
$dark-color: #1E232A;


$alert-color: #b50000;

$default-transition: all 0.4s;
$default-input-height: 66px;

:export {
  x-small-breakpoint: $x-small-breakpoint;
  small-breakpoint: $small-breakpoint;
  medium-breakpoint: $medium-breakpoint;
  large-breakpoint: $large-breakpoint;
}