.about {
  &__container-top {
    display: flex;
    flex-wrap: wrap;
  }

  &__text {
    p {
      color: #000;
    }
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
  }

  &__val {
    line-height: 1;
    color: #FF6651;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    position: relative;
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__bot {
    background-color: #fff;
  }
}

@include respond-up('large') {
  .about {
    &__container-top {
      padding-bottom: 120px;
    }

    &__description-top {
      flex: 0 0 50%;
      padding-right: 90px;
    }

    &__text {
      padding-bottom: 26px;

      p {
        font-size: 24px;
      }
    }

    &__values {
      flex: 0 0 50%;
      padding-left: 74px;
      margin-top: -6px;
      max-height: 300px;
    }

    &__value {
      flex: 0 0 50%;
      margin-bottom: 25px;
      padding-right: 60px;
    }

    &__val {
      font-size: 55px;
      padding-bottom: 8px;
    }

    &__name {
      font-size: 18px;
    }

    &__images {
      margin: 0 -20px;
    }

    &__image {
      padding-top: 25%;

      &:nth-child(2n) {
        top: 45px;
      }
    }

    &__img {
      padding: 0 20px;
    }

    &__container-bot {
      display: flex;
      padding-bottom: 110px;
    }

    &__blockquote {
      flex: 0 0 50%;
      padding: 0 60px 0 40px;
    }

    &__description-bot {
      padding-right: 320px;
    }

    &__bot {
      padding: 164px 0 132px;
      background: {
        image: url("../images/lace-bottom-right.png");
        repeat: no-repeat;
        position: bottom right;
      };
    }
  }
}

@include respond-up('medium') {
  .about {
    &__pictures {
      background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
    }

    &__image {
      flex: 0 0 25%;
    }
  }
}

@include respond('medium') {
  .about {
    &__container-top {
      padding-bottom: 80px;
    }

    &__text {
      p {
        font-size: 22px;
      }
    }

    &__values {
      padding-top: 44px;
    }

    &__value {
      flex: 0 0 25%;
    }

    &__val {
      padding-bottom: 12px;
    }

    &__name {
      max-width: 166px;
      font-size: 16px;
    }

    &__images {
      margin: 0 -12px;
    }

    &__image {
      padding-top: 28%;
    }

    &__img {
      padding: 0 12px 24px;
    }

    &__container-bot {
      padding-bottom: 70px;
    }

    &__blockquote {
      padding-left: 30px;
      
      &:first-child {
        padding-bottom: 50px;
      }
    }

    &__bot {
      padding: 100px 0;
    }
  }
}

@include respond-down('medium') {
  .about {
    &__description-top {
      flex: 0 0 100%;
    }

    &__text {
      padding-bottom: 22px;
    }

    &__values {
      flex: 0 0 100%;
    }

    &__val {
      font-size: 45px;
    }

    &__image {
      &:nth-child(2n) {
        top: 25px;
      }
    }
  }
}

@include respond-down('small') {
  .about {
    &__container-top {
      padding-bottom: 50px;
    }

    &__text {
      p {
        font-size: 18px;
      }
    }

    &__values {
      padding-top: 34px;
    }

    &__value {
      flex: 0 0 50%;
      padding-top: 16px;
    }

    &__val {
      padding-bottom: 10px;
    }

    &__name {
      max-width: 140px;
      padding-right: 20px;
      font-size: 15px;
    }

    &__pictures {
      background: linear-gradient(to bottom, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 75%);
    }

    &__images {
      margin: 0 -11px;
    }

    &__image {
      flex: 0 0 50%;
      padding-top: 55%;
    }

    &__img {
      padding: 0 11px 22px;
    }

    &__container-bot {
      padding-bottom: 50px;
    }

    &__blockquote {
      padding-left: 20px;

      &:first-child {
        padding-bottom: 30px;
      }
    }

    &__bot {
      padding: 68px 0 50px;
    }
  }
}

@include respond-down('x-small') {
  .about {
    &__value {
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__name {
      max-width: 180px;
      padding: 0;
    }
  }
}