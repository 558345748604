.product-list {
  &__offer-image {
    padding-top: 60%;
  }

  &__offer-img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .product-list {
    &__offer-img {
      height: 520px;
    }
  }
}

@include respond-down('medium') {
  .product-list {
    &__offer-img {
      height: 100%;
    }
  }
}