.advice {
  &__form {
    padding: 120px;
    max-width: 600px;
  }

  &__title {
    font-size: 21px;
    padding-bottom: 20px;
    text-align: center;
  }

  &__text {
    padding-bottom: 35px;
    color: rgba(0,0,0,0.56);
    text-align: center;
  }

  &__field {
    input[type=text] {
      background: #F2F3F4;
      border: 1px solid #E6E8EB;
      border-radius: 100px;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      line-height: 24px;
      margin: 15px 0 30px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__button {
    height: 60px;
    line-height: 60px;
    font-weight: bold;
  }

  &__personal {
    padding-top: 20px;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0,0,0,0.35);
    text-align: center;
  }

  &__image {
    padding-left: 30%;
    img{
      width: 60%;
    }
  }
  &__link {
    color: rgba(0,0,0,0.35);
  }
}

@include respond-down('small') {
  .advice {
    &__form {
      padding: 30px 0;
    }

    &__title {
      font-size: 16px;
    }

    &__text {
      padding-bottom: 15px;
    }
  }
}