.products {
  background-color: $grey-color;


  &__title {

  }

  &__list {

  }

  &__item {

  }
  &__slider-nav {
    &_hide{
      display: none;
    }
  }
}

@include respond-up('large') {
  .products {
    padding: 146px 0 100px;

    &__list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 80px;
    }

    &__item {
      padding: 0 20px 40px;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__container {
      max-width: #{$max-row-width}px;
      margin: 0 auto;
    }
  }
}

@include respond-down('medium') {
  .products {
    .tns-ovh {
      overflow: initial;
    }

    &__slider-nav {
      display: none;
    }
  }
}

@include respond('medium') {
  .products {
    padding: 75px 0;

    $item-padding: 15px;

    &__list {
      padding-top: 54px;

      &::before, &::after {
        width: ($medium-spacer - $item-padding);
        flex: 0 0 ($medium-spacer - $item-padding);
        content: '';
        height: 20px;
        display: block;
      }
    }

    &__item {
      padding: 0 $item-padding;
      flex: 0 0 265px;

      &:first-child {
        flex: 0 0 284px;
      }

      &:last-child {
        flex: 0 0 284px;

      }
    }
  }
}

@include respond-down('medium') {
  .products {
    padding: 146px 0 136px;

    &__container {
      overflow: hidden;
    }

    &__wrapper {
      overflow-y: hidden;
      overflow-x: scroll;
      margin-bottom: -40px;
      padding-bottom: 40px;
    }

    &__list {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
    }
  }
}

@include respond-down('small') {
  .products {
    padding: 34px 0 50px;

    $item-padding: 10px;

    &__list {
      padding-top: 36px;

      &::before, &::after {
        width: ($small-spacer - $item-padding);
        flex: 0 0 ($small-spacer - $item-padding);
        content: '';
        height: 20px;
        display: block;
      }
    }

    &__item {
      padding: 0 $item-padding;
      flex: 0 0 200px;
    }
  }
}