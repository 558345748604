.like-it {
  background-color: #FFFFFF;

  &__container {
    display: flex;
  }

  &__images {
    display: flex;
  }

  &__image-big {
    background: {
      image: url("../images/like1.png");
      repeat: no-repeat;
      size: contain;
    };
  }

  &__image-container {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    flex: 0 0 46.5%;
  }

  &__small {
    margin-bottom: 34px;
    background: {
      image: url("../images/like2.png");
      repeat: no-repeat;
      size: contain;
    };
  }

  &__x-small {
    background: {
      image: url("../images/like3.png");
      repeat: no-repeat;
      size: contain;
    };
  }
}

@include respond-up('large') {
  .like-it {
    background: url('../images/show-lace-top.png') no-repeat top left #FFFFFF;

    &__wrapper {
      background: url('../images/show-lace-bottom.png') no-repeat bottom right;
      padding: 135px 0;
    }

    &__container {
      align-items: center;
    }

    &__info {
      flex: 0 0 60%;
    }

    &__images {
      height: 566px;
      flex: 0 0 40%;
    }

    &__image-big {
      height: 100%;
      flex: 0 0 53.5%;
    }

    &__image-container {
      margin-left: 35px;
      display: flex;
      flex-direction: column;
      flex: 0 0 46.5%;
    }

    &__small {
      margin-bottom: 34px;
      height: 318px;
    }

    &__x-small {
      height: 214px;
    }
  }
}

@include respond('medium') {
  .like-it {
    &__wrapper {
      padding: 83px 0;
    }

    &__container {
      align-items: stretch;
    }

    &__info {
      flex: 0 0 73.5%;
    }

    &__images {
      height: 412px;
      flex: 0 0 26.5%;
    }

    &__image-big {
      height: 100%;
      flex: 0 0 100%;
    }

    &__image-container {
      display: none;
    }
  }
}

@include respond-down('small') {
  .like-it {
    &__wrapper {
      padding: 41px 0 50px;
    }

    &__info {
      flex: 0 0 100%;
    }

    &__container {
      flex-direction: column;
    }

    &__images {
      margin-top: 47px;
      height: 393px;
      flex: 0 0 26.5%;
      justify-content: space-between;
      overflow: hidden;
    }

    &__image-big {
      height: 100%;
      background-size: cover;
      flex: 0 0 53.5%;
    }

    &__image-container {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      flex: 0 0 46.5%;
      max-width: 128px;
    }

    &__small {
      margin-bottom: 23px;
      height: 221px;
    }

    &__x-small {
      height: 149px;
    }
  }
}