.contacts {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    text-decoration: none;
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    color: rgba(0,0,0,0.36);
    line-height: 24px;
  }

  &__info {
    color: #000000;
  }
}

@include respond-up('large') {
  .contacts {
    &__wrapper {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    &__list {
      margin: 0 -38px;
    }

    &__item {
      flex: 0 0 30%;
      padding: 0 38px 62px;

      &:nth-child(3n+2) {
        flex: 0 0 32%;
      }

      &:nth-child(3n+1) {
        flex: 0 0 38%;
      }
    }

    &__link {
      display: flex;
    }

    &__content {
      margin-left: 24px;
    }

    &__name {
      font-size: 16px;
    }

    &__info {
      font-size: 20px;
    }

    &__exhibition {
      flex: 0 0 55%;
    }

    &__map {
      flex: 0 0 45%;
      height: 560px;
    }
  }
}

@include respond('medium') {
  .contacts {
    &__wrapper {
      padding-top: 30px;
      padding-bottom: 20px;
    }

    &__item {
      flex: 0 0 50%;
      padding: 0 37px 56px 0;
    }

    &__content {
      margin-left: 24px;
    }

    &__name {
      font-size: 16px;
    }

    &__info {
      font-size: 20px;
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__exhibition {
      flex: 0 0 100%;
    }

    &__map {
      flex: 0 0 100%;
      height: 420px;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__wrapper {
      padding-top: 15px;
      padding-bottom: 40px;
    }

    &__item {
      flex: 0 0 100%;
      padding-bottom: 24px;
    }

    &__content {
      margin-left: 21px;
      justify-content: center;
    }

    &__name {
      font-size: 15px;
      padding-bottom: 5px;
    }

    &__info {
      font-size: 17px;
    }

    &__map {
      height: 270px;
    }
  }
}