.action {
  &__image {
    background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
  }

  &__img {
    width: 100%;
  }

  &__container {
    background-color: #fff;
  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E3E3E3;
  }

  &__share {
    display: flex;
    align-items: center;
  }

  &__share-name {
    padding-right: 24px;
  }

  &__more {
    border-bottom: 1px solid #E3E3E3;
  }

  &__link {
    display: flex;
    border-bottom: 1px solid #E3E3E3;
  }

  &__link-action {
    color: $bright-blue-color;
    text-decoration: none;
  }

  &__products {
    background-color: transparent;
  }
}

@include respond-up('large') {
  .action {
    &__main {
      padding: 70px 0 126px;
    }

    &__more-actions {
      flex: 0 0 380px;

      &_desktop {
        margin-left: 145px;
        margin-bottom: 47px;
      }

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .action {
    &__img {
      &_mobile {
        display: none;
      }
    }

    &__description {
      padding-bottom: 68px;
    }

    &__bot {
      padding-top: 22px;
      font-size: 15px;
    }

    &__more {
      font-size: 25px;
      padding-bottom: 32px;
    }

    &__link-action {
      padding: 22px 0 24px;
      font-size: 17px;
    }
  }
}

@include respond('medium') {
  .action {
    &__main {
      padding: 52px 0 62px;
    }

    &__more-actions {
      padding: 66px 0 74px;
    }
  }
}

@include respond-down('medium') {
  .action {
    &__main {
      flex-wrap: wrap;
    }

    &__more-actions {
      flex: 0 0 100%;

      &_desktop {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .action {
    &__image-container {
      position: relative;
      padding-bottom: 90%;
    }

    &__img {
      &_mobile {
        position: absolute;
        height: 100%;
        object-fit: contain;
      }

      &_desktop {
        display: none;
      }
    }

    &__main {
      padding: 28px 0 50px;
    }

    &__description {
      padding-bottom: 42px;
    }

    &__bot {
      padding-top: 16px;
      font-size: 14px;
    }

    &__share-name {
      display: none;
    }

    &__more {
      font-size: 22px;
      padding-bottom: 22px;
    }

    &__more-actions {
      padding: 44px 0 50px;
    }

    &__link-action {
      padding: 14px 0 16px;
      font-size: 16px;
    }
  }
}