.products-list {
  display: flex;
  flex-wrap: wrap;

  &__action-inner{
    background-color: #c2c2c2;
    height: 100%;
  }

  &__action-inner-link {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__offer-link {
    display: block;
    height: 100%;
    width: 100%;
    background: {
      size: cover;
      position: 50% 50%;
      repeat: no-repeat;
    };
  }
}

@include respond-up('large') {
  .products-list {
    margin: 0 -21px;
    padding-bottom: 90px;
    &__product {
      padding: 0 21px 40px;
      flex: 0 0 25%;

      &_hide{
        display: none;
      }

      &_after-action{
        display: list-item;
      }
      &_before-action{
        display: none;
      }

      &_large-hide{
        display: none;
      }

      &_offer {
        flex: 0 0 50%;
      }
    }

    &__action{
      padding: 0 21px 40px;
      &_large{
        flex: 0 0 75%;
      }
      &_small{
        flex: 0 0 50%;
      }
    }
  }
}

@include respond('medium') {
  .products-list {
    margin: 0 -15px;
    padding-bottom: 40px;
    &__product {
      padding: 0 15px 30px;
      flex: 0 0 33.333%;

      &_before-action{
        display: list-item;
      }

      &_after-action{
        display: none;
      }

      &_medium-hide{
        display: none;
      }

      &_offer {
        flex: 0 0 66.66666%;
      }
    }

    &__action{
      padding: 0 15px 30px;
      &_large, &_small{
        flex: 0 0 66.666%;
      }
    }
  }
}

@include respond-down('small') {
  .products-list {
    margin: 0 -10px;
    padding-bottom: 30px;
    &__product {
      padding: 0 10px 20px;
      flex: 0 0 50%;

      &_before-action{
        display: list-item;
      }
      &_hide{
        display: none;
      }
      &_after-action{
        display: none;
      }

      &_small-hide{
        display: none;
      }

      &_offer {
        flex: 0 0 100%;
      }
    }

    &__offer-link {
      padding-top: 83%;
    }

    &__action{
      padding: 0 10px 20px;
      height: auto;
      &_large, &_small{
        flex: 0 0 100%;
      }
    }
    &__action-inner-link {
      padding-bottom: 83%;
      height: auto;
    }
  }
}