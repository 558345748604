.review {
  display: flex;
  &__user {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    border-radius: 100px;
    width: 85px;
    height: 85px;
    background: {
      repeat: no-repeat;
      position: 50% 50%;
      size: cover;
    };
    &_empty {
      background-image: url('../svg/user.svg');
    }
  }

  &__letters-text {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 85px;
    height: 85px;
    background-color: $primary-color;
    color: #fff;
  }

  &__vk {
    position: absolute;
    bottom: 18px;
    right: 0;
  }

  &__name {
    text-align: center;
    font-weight: bold;
  }

  &__date {
    color: #919191;
    letter-spacing: 0.87px;
    text-align: center;
    line-height: 24px;
  }

  &__text {
    color: rgba(0,0,0,0.56);
    line-height: 1.8;
    max-width: 815px;
  }

  &__answer-wrapper {
    display: flex;
  }

  &__answer {
    color: rgba(0,0,0,0.56);
  }

  &__company {
    display: flex;
  }

  &__company-date {
    color: #919191;
    letter-spacing: 0.87px;
    line-height: 24px;
  }

  &__company-name {
    font-weight: bold;
    color: #000000;
    line-height: 24px;
  }
}

@include respond-up('large') {
  .review {
    &__user {
      flex: 0 0 188px;
    }

    &__image-container {
      padding-bottom: 26px;
    }

    &__name {
      font-size: 17px;
      padding-bottom: 14px;
      max-width: 188px;
    }

    &__date {
      font-size: 13px;
    }

    &__container {
      padding-left: 100px;
    }

    &__text {
      font-size: 17px;
      max-width: 738px;
    }

    &__answer-wrapper {
      padding-top: 32px;
    }

    &__answer-corner {
      padding-right: 36px;
      &_big {
        display: block;
      }

      &_medium-down {
        display: none;
      }
    }

    &__answer-container {
      padding-top: 28px;
    }

    &__answer {
      font-size: 16px;
    }

    &__company {
      padding-top: 32px;
    }

    &__company-wrapper {
      padding-left: 29px;
    }

    &__company-date {
      font-size: 13px;
    }

    &__company-name {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .review {
    &__user {
      flex: 0 0 188px;
    }

    &__image-container {
      padding-bottom: 18px;
    }

    &__name {
      font-size: 16px;
      padding-bottom: 8px;
      max-width: 188px;
    }

    &__date {
      font-size: 13px;
    }

    &__container {
      padding-left: 47px;
    }

    &__text {
      font-size: 16px;
    }

    &__answer-wrapper {
      padding-top: 34px;
    }

    &__answer-corner {
      padding-right: 36px;
      &_big {
        display: none;
      }

      &_medium-down {
        display: block;
      }
    }

    &__answer-container {
      padding-top: 14px;
    }

    &__answer {
      font-size: 15px;
    }

    &__company {
      padding-top: 29px;
    }

    &__company-wrapper {
      padding-left: 20px;
    }

    &__company-date {
      font-size: 13px;
    }

    &__company-name {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .review {
    flex-direction: column;
    &__user {
      flex-direction: row;
      padding-bottom: 24px;
    }

    &__data-container {
      padding-left: 24px;
    }

    &__vk {
       bottom: -5px;
    }

    &__name {
      font-size: 16px;
      padding-bottom: 8px;
      max-width: 188px;
      text-align: left;
    }

    &__date {
      font-size: 13px;
      text-align: left;
    }

    &__text {
      font-size: 15px;
    }

    &__answer-wrapper {
      padding-top: 20px;
    }

    &__answer-corner {
      padding-right: 18px;
      &_big {
        display: none;
      }

      &_medium-down {
        display: block;
      }
    }

    &__answer-container {
      padding-top: 15px;
    }

    &__answer {
      font-size: 15px;
    }

    &__company {
      padding-top: 14px;
    }

    &__company-wrapper {
      padding-left: 18px;
    }

    &__company-date {
      font-size: 13px;
    }

    &__company-name {
      font-size: 15px;
    }
  }
}