.catalog-page{
  &__filter-block{
    padding-bottom: 58px;
  }

  &__products-list{
    padding-bottom: 22px;
  }

  &__button-wrapper{
    padding-bottom: 179px;
    width: 258px;
    margin: auto;
  }

  &__button-other{
    height: 66px;
    line-height: 66px;
  }
}

@include respond('medium'){
  .catalog-page{
    &__filter-block{
      padding-bottom: 43px;
    }
  }
}

@include respond('small'){
  .catalog-page{
    &__filter-block{
      padding-bottom: 32px;
    }
  }
}