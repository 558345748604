.action-card {
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }

  &__top {
    text-decoration: none;
    color: #000;
  }

  &__description {
    padding: 12px 0 24px;
    color: rgba(0,0,0,0.56);
  }

  &__link {
    display: flex;
    align-items: center;
    align-self: flex-start;
    text-decoration: none;
    border-bottom: 1px solid rgba(81,101,214,0.25);
  }

  &__more {
    color: $bright-blue-color;
    padding-left: 8px;
  }
}

@include respond-up('large') {
  .action-card {
    &__content {
      padding: 62px 68px;
    }

    &__image {
      flex: 0 0 412px;
    }
  }
}

@include respond-up('medium') {
  .action-card {
    &__link {
      padding-bottom: 6px;
    }
  }
}

@include respond('medium') {
  .action-card {
    &__content {
      padding: 30px 38px;
    }

    &__image {
      flex: 0 0 316px;
    }
  }
}

@include respond-down('small') {
  .action-card {
    &__image {
      flex: 0 0 100%;
      position: relative;
      padding-bottom: 83%;
    }

    &__img {
      position: absolute;
    }

    &__content {
      padding: 28px 24px 30px;
    }

    &__link {
      padding-bottom: 4px;
    }
  }
}