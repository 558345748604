.button {
  padding: 0 20px;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  background: $primary-color;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-family: $font;
  height: $default-input-height;
  line-height: $default-input-height;
  font-size: 15px;
  letter-spacing: 0.94px;
  border-radius: 100px;

  &_expand, &_wide {
    width: 100%;
  }

  &_secondary {
    background: $secondary-color;
  }


  &_transparent {
    color: #FF6651;
    background-color: $t;
    border: 1px solid $secondary-color;
  }

  &_huge {
    padding: 0 55px;
    height: 66px;
    line-height: 66px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: (1/16)*1em;
  }

  &_blue-transparent {
    padding: 0 55px;
    height: 66px;
    line-height: 66px;
    color: $primary-color;
    box-shadow: inset 0 0 0 1px $primary-color;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
  }

  &_footer {
    padding: 0 26px;
    height: 52px;
    line-height: 52px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    background-color: $primary-color;
  }
}

@include respond('medium') {
  .button {
    &_huge {
      padding: 0 38px;
      height: 59px;
      line-height: 59px;
    }

    &_blue-transparent {
      padding: 0 38px;
      height: 59px;
      line-height: 59px;
    }
  }
}

@include respond-down('small') {
  .button {
    &_huge {
      font-size: 15px;
      padding: 0 53px;
      height: 53px;
      line-height: 53px;
    }

    &_blue-transparent {
      font-size: 15px;
      padding: 0 53px;
      height: 53px;
      line-height: 53px;
    }
  }
}