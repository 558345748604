.line-form {
  position: relative;

  &__line {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__data {
    position: relative;
    ul {
      position: absolute;
      display: block;
      bottom: -6px;
      right: 0;
      left: 25px;
      li {
        white-space: nowrap;
      }
    }
  }

  &__button {
    background-color: $secondary-color;
  }

  &__personal {
    color: rgba(0,0,0,0.35);
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    z-index: 1;
    transition: all 0.4s;
    a {
      color: rgba(0,0,0,0.35);
    }
  }
}

.line-form_recall {
  .line-form {
    &__personal {
      color: rgba(255,255,255,0.35);
      a {
        color: rgba(255,255,255,0.35);
      }
    }
  }
}

@include respond-up('large') {
  .line-form {
    &__line {
      display: flex;
      align-items: center;
    }

    &__data {
      flex: 0 0 50%;

      input[type=text] {
        border-radius: 100px 0 0 100px;
      }
    }

    &__button {
      flex: 0 0 50%;
      border-radius: 0 100px 100px 0;
    }

    &__personal {
      padding: 5px 10px 0;
      font-size: 12px;
    }
  }

  .line-form_recall {
    .line-form {
      &__personal {
        padding: 0 10px;
        a {
          color: rgba(255,255,255,0.35);
        }
      }
    }
  }

  .line-form_contacts {
    .line-form {
      &__line {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }

      &__data {
        flex: 0 0 100%;
        margin-bottom: 8px;

        input[type=text] {
          border-radius: 100px;
          margin: 0;
        }

        ul {
          position: absolute;
          display: block;
          bottom: -7px;
          right: 0;
          left: 25px;
          li {
            white-space: nowrap;
          }
        }
      }

      &__button {
        flex: 0 0 100%;
        border-radius: 100px;
      }

      &__personal {
        padding: 5px 10px 0;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

@include respond('medium') {
  .line-form {
    &__line {
      display: flex;
      flex-direction: column;
    }

    &__data {
      flex: 0 0 100%;
      margin-bottom: 8px;

      input[type=text] {
        border-radius: 100px;
        margin: 0;
        height: 59px;
      }

      ul {
        position: absolute;
        display: block;
        bottom: -7px;
        right: 0;
        left: 25px;
        li {
          white-space: nowrap;
        }
      }
    }

    &__button {
      flex: 0 0 100%;
      border-radius: 100px;
      line-height: 59px;
      height: 59px;
    }

    &__personal {
      padding: 11px 10px 0;
      font-size: 12px;
    }

    .request-form {
      &__data {
        margin-right: 100px;
        min-width: 293px;
      }
    }
  }

  .line-form_recall {
    .line-form {
      &__line {
        flex-direction: row;
        align-items: flex-end;
      }

      &__data {
        flex: 0 0 50%;
        margin: 0;

        input[type=text] {
          border-radius: 100px 0 0 100px;
          margin: 0;
        }

        .form-field {
          margin: 0;
        }
      }

      &__button {
        flex: 0 0 50%;
        border-radius: 0 100px 100px 0;
      }

      &__personal {
        text-align: center;
      }
    }

    .request-form {
      &__data {
        margin: 0;
      }
    }
  }

  .line-form_contacts {
    .line-form {
      &__line {
        flex-direction: row;
        align-items: flex-end;
      }

      &__data {
        flex: 0 0 50%;
        margin: 0;

        input[type=text] {
          border-radius: 100px 0 0 100px;
          margin: 0;
        }

        .form-field {
          margin: 0;
        }
      }

      &__button {
        flex: 0 0 50%;
        border-radius: 0 100px 100px 0;
      }

      &__personal {
        text-align: left;
        letter-spacing: 0;
      }
    }

    .request-form {
      &__data {
        margin: 0;
      }
    }
  }
}

@include respond-down('small') {
  .line-form {
    &__line {

    }

    &__data {
      width: 100%;
      margin-bottom: 16px;

      input[type=text] {
        border-radius: 100px;
        margin: 0;
        height: 53px;
      }

      ul {
        position: absolute;
        display: block;
        bottom: -18px;
        right: 0;
        left: 25px;
        li {
          white-space: nowrap;
        }
      }
    }

    &__button {
      width: 100%;
      border-radius: 100px;
      line-height: 53px;
      height: 53px;
    }

    &__personal {
      margin-top: 12px;
      font-size: 12px;
    }

    .request-form {
      &__data {
        width: 100%;
      }
    }
  }

  .line-form_recall {
    .line-form {
      &__personal {
        text-align: center;
      }
    }
  }
}