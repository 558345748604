.error-page {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__404 {
    font-size: 180px;
    line-height: 180px;
    color: $secondary-color;
  }

  &__text {
    font-size: 24px;
    text-align: center;
    color: $secondary-color;
  }

  &__buttons {
    padding-top: 60px;
  }

  &__button {

  }
}

@include respond-up('large') {
  .error-page {
    &__main {
      padding: 100px 0 120px;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__main {
      padding: 60px 0 100px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__main {
      padding: 40px 0 80px;
    }
  }
}