form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 16px;
    color: #000000;
    width: 100%;
    height: 98%;
    display: block;
    appearance: none;
    font-family: $font;
    /* Rectangle 5: */
    background: #FFFFFF;
    border: 1px solid #E6E8EB;
    border-radius: 8px;
  }

  #{$inputs}, select {
    background: #FFFFFF;
    border: 1px solid #E6E8EB;
    border-radius: 8px;
    height: $default-input-height;
    padding: 19px 24px;
  }

  input[type="text"] {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  textarea {
    padding: 19px 24px;
  }

}

button {
  appearance: none;
}

@include respond('medium') {
  form{
    input[type="text"] {
      margin-bottom: 30px;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}

@include respond-down('small') {
  form{
    input[type="text"] {
      margin-bottom: 15px;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
    }
  }
}