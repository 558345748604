.best {
  background-color: $grey-color;
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: .8s;

  &.need-animation{
    opacity: 0;
    transform: translateY(40px);
    transition-property: none;
  }
  &__head {
    padding-top: 135px;
    padding-bottom: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(81,101,214,0.25);
    color: $primary-color;
    text-decoration: none;
  }

  &__cubes {
    display: flex;
    align-items: center;
    padding-right: 12px;
  }

  &__catalog {
    font-weight: bold;
    font-size: 16px;
    font: $font;
  }
}

@include respond('medium') {
  .best {
    &__head {
      padding: 69px 0 59px;
    }
  }
}

@include respond-down('small') {
  .best {
    &__head {
      padding: 40px 0;
      flex-wrap: wrap;
    }

    &__title {
      flex: 0 0 100%;
      padding-bottom: 20px;
    }
  }
}