.recall-line {
  background: #1E232A no-repeat;
  background-size: contain;
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: .6s;


  &__wrapper {
    align-items: center;
    justify-content: space-between;
  }

  &__question-block {
    color: #FFFFFF;
    display: flex;
  }

  &__question {
    font-family: $h-font;
  }

  &__text {
    padding-top: 29px;
    font-size: 20px;
  }

  &.need-animation{
    transition-property: none;
    opacity: 0;
    transform: translateY(30px);
  }
}

@include respond-up('large') {
  .recall-line {
    &__wrapper {
      padding: 135px 0;
      display: flex;
    }

    &__question-block {
      flex-direction: column;
    }

    &__question {
      font-size: 42px;
    }

    &__text {
      padding-top: 29px;
      font-size: 20px;
    }

    &__form {
      margin-left: 89px;
    }
  }
}

@include respond('medium') {
  .recall-line {
    &__wrapper {
      padding: 58px 0 69px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__question-block {
      flex-direction: column;
      align-items: center;
      flex: 0 0 100%;
    }

    &__question {
      font-size: 27px;
    }

    &__text {
      padding-top: 26px;
      font-size: 20px;
    }

    &__form {
      margin-top: 40px;
      width: 552px;
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .recall-line {
    &__wrapper {
      padding: 50px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__question-block {
      flex-direction: column;
      align-items: center;
    }

    &__question {
      font-size: 21px;
      text-align: center;
    }

    &__text {
      padding-top: 13px;
      font-size: 15px;
      text-align: center;
    }

    &__form {
      margin-top: 32px;
      max-width: 410px;
    }
  }
}