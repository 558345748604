@font-face {
    font-family: 'Heuristica';
    src: url('../fonts/Heuristica-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Heuristica-Bold.woff') format('woff'), url('../fonts/Heuristica-Bold.ttf')  format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Heuristica';
    src: url('../fonts/Heuristica-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Heuristica-BoldItalic.woff') format('woff'), url('../fonts/Heuristica-BoldItalic.ttf')  format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Heuristica';
    src: url('../fonts/Heuristica-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Heuristica-Italic.woff') format('woff'), url('../fonts/Heuristica-Italic.ttf')  format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Heuristica';
    src: url('../fonts/Heuristica-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/Heuristica-Regular.woff') format('woff'), url('../fonts/Heuristica-Regular.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
