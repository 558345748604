.flexible-image {
  display: block;
  transform: translate3d(0, 0, 0);

  &__container {
    position: relative;
    padding-top: 140%;
    overflow: hidden;
    display: block;
  }

  &__image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: {
      position: 50% 50%;
      size: cover;
      repeat: no-repeat;
    };
    z-index: 5;

    &_add{
      opacity: 1;
      z-index: 10;
      transition-duration: .5s;
      transition-property: opacity;
      transition-timing-function: ease-in-out;

      &:hover{
        opacity: 0;
      }
    }
  }


  &__img {
    display: none !important;
  }
}