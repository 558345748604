.contacts-map {
  position: relative;
  background: #fff;

  &__container {
    display: flex;
  }

  &__exhibition {
    display: flex;
    flex-direction: column;
  }

  &__caption-city {
    color: $primary-color;
    text-decoration: none;
  }

  &__text {
    color: rgba(0,0,0,0.56);
    letter-spacing: 0;
    line-height: 24px;
  }

  &__streak {
    background-color: $grey-color;
    border-left: 4px solid $secondary-color;
  }

  &__date {
    color: $primary-color;
    font-family: $h-font;
  }

  &__map {
    position: absolute;
    right: 0;
  }
}

@include respond-up('large') {
  .contacts-map {
    padding: 100px 0 96px;

    &__container {
      display: flex;
    }

    &__exhibition {
      flex: 0 0 38%;
      min-height: 560px;
    }

    &__caption {
      padding-bottom: 34px;
    }

    &__text {
      font-size: 16px;
      padding-bottom: 93px;
    }

    &__map {
      top: 100px;
      left: 55%;
      height: 560px;
    }

    &__streak {
      padding: 30px 40px 30px 34px;
    }

    &__date {
      font-size: 22px;
      padding-bottom: 13px;
      font-family: $h-font;
    }

    &__address {
      font-size: 20px;
    }
  }

  .contacts-map_trade {
    background: $grey-color;
    .contacts-map {
      &__exhibition {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        min-height: 100%;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        padding-right: 160px;
      }

      &__caption {
        padding-bottom: 37px;
        max-width: 535px;
      }

      &__text {
        font-size: 16px;
        padding-bottom: 0;
        max-width: 460px;
      }

      &__streak {
        background-color: #FFFFFF;
        flex: 0 0 38%;
        padding: 30px 40px 30px 34px;
      }

      &__date {
        font-size: 22px;
        padding-bottom: 13px;
        font-family: $h-font;
      }

      &__address {
        font-size: 20px;
      }
    }
  }
}

@include respond('medium') {
  .contacts-map {
    padding: 56px 0 64px;

    &__exhibition {
      padding-bottom: 486px;
      flex: 0 0 65%;
    }

    &__caption {
      padding-bottom: 26px;
      max-width: 500px;
    }

    &__text {
      font-size: 16px;
      padding-bottom: 46px;
    }

    &__streak {
      padding: 30px 40px 30px 34px;
      margin-right: 40px;
    }

    &__date {
      font-size: 19px;
      padding-bottom: 10px;
    }

    &__address {
      font-size: 18px;
    }

    &__map {
      left: 40px;
      bottom: 64px;
      height: 420px;
    }
  }

  .contacts-map_trade {
    background: $grey-color;
    .contacts-map {
      &__exhibition {
        padding-bottom: 0;
        padding-right: 200px;
        flex: 0 0 100%;
      }

      &__text {
        max-width: 453px;
      }

      &__streak {
        background-color: #FFFFFF;
        max-width: 383px;
        margin-right: 0;
      }

      &__date {
        font-size: 17px;
      }

      &__address {
        font-size: 16px;
      }
    }
  }
}


@include respond-down('small') {
  .contacts-map {
    padding: 40px 0 50px;

    &__exhibition {
      padding-bottom: 305px;
      flex: 0 0 100%;
    }

    &__caption {
      padding-bottom: 26px;
    }

    &__text {
      font-size: 15px;
      padding-bottom: 35px;
    }

    &__streak {
      padding: 21px 30px 18px 21px;
      max-width: 330px;
    }

    &__date {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: $h-font;
    }

    &__address {
      font-size: 14px;
    }

    &__map {
      left: 20px;
      bottom: 50px;
      height: 270px;
      flex: 0 0 100%;
    }
  }

  .contacts-map_trade {
    background: $grey-color;
    .contacts-map {
      &__exhibition {
        padding-bottom: 0;
        flex: 0 0 100%;
      }

      &__streak {
        background-color: #FFFFFF;
        max-width: 330px;
      }
    }
  }
}