.breadcrumbs {
  margin-bottom: 15px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__item, &__link {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.56);
  }

  &__item {
    &_delimiter {
      color: $secondary-color;
      margin: 0 7px;
    }
  }

  &__link {
    text-decoration: none;
  }
}

@include respond('medium') {
  .breadcrumbs {
    margin-bottom: 10px;
  }
}

@include respond-down('small') {
  .breadcrumbs {
    margin-bottom: 7px;

    &__item, &__link {
      font-size: 15px;
    }

    &__item {
      &_delimiter {
        margin: 0 6px;
      }
    }
  }
}