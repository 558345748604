.article {
  &, p, li {
    font-size: 16px;
  }

  p, li {
    color: rgba(0,0,0,0.56);
  }

  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 27px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol li {
    &::before {
      display: inline-block;
      width: 36px;
      font-size: 20px;
      color: #FF6651;
    }
  }

  ol li p {
    line-height: 1.2;
  }

  blockquote {
    position: relative;
    font-size: 24px;
    &::before {
      content: '';
      position: absolute;
      left: -40px;
      height: 100%;
      border-left: 3px solid $secondary-color;
    }
  }
}

@include respond('medium') {
  .article {
    h1 {
      font-size: 27px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 17px;
    }

    h6 {
      font-size: 16px;
    }

    blockquote {
      font-size: 22px;

      &::before {
        left: -30px;
      }
    }
  }
}

@include respond-down('small') {
  .article {
    &, p, li {
      font-size: 15px;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 15px;
    }

    h5 {
      font-size: 15px;
    }

    h6 {
      font-size: 15px;
    }

    ol li {
      &::before {
        font-size: 18px;
      }
    }

    blockquote {
      font-size: 18px;

      &::before {
        left: -20px;
      }
    }
  }
}

@for $i from 1 to 99 {
  ol li:nth-child(#{$i})::before {
    content: $i + '.';
  }
}