.success-modal {
  display: flex;

  &__icon {
    padding-right: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: 400;
    font-family: $h-font;
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }

  &__description {
    padding-top: 12px;
    color: rgba(0,0,0,0.55);
  }
}

@include respond-up('medium') {
  .success-modal {
    padding: 50px;

    &__icon {
      padding-right: 40px;
    }
  }
}


@include respond-down('small') {
  .success-modal {
    padding: 40px 0;

    &__icon {
      padding-right: 20px;
    }
  }
}