.show-products {
  background-color: #FFFFFF;
  opacity: 1;
  transition-property: opacity;
  transition-duration: .7s;


  &__wrapper {
    height: 100%;
  }

  &__container {

  }

  &__list {
    display: flex;
    justify-content: space-between;
    @for $i from 1 through 3 {
      .show-card{
        transition-duration: .5s;
        opacity: 1;
        transition-property: opacity, transform;
        &:nth-child(#{$i}){
          transition-delay: calc(#{$i} * 0.2s);
        }
      }
    }
    &.need-animation{
      .show-card{
        opacity: 0;
        transform: translateY(40px);
        transition-property: none;
      }
    }
  }
}

@include respond-up('large') {
  .show-products {
    background: #FFFFFF;

    &__wrapper {
      background: url('../images/show-lace-bottom.png') no-repeat bottom right;
    }

    &__container {
      padding: 135px 0;
    }

    &__list {
      margin: 0 -20px;

    }

    &.need-animation{
      opacity: 0;
      transition-property: none;
    }
  }
}

@include respond('medium') {
  .show-products {
    &__wrapper {

    }

    &__container {
      padding: 75px 0;
    }

    &__list {
      margin: 0 -16px;
    }
  }
}

@include respond-down('small') {
  .show-products {
    &__wrapper {

    }

    &__container {
      padding: 72px 0 30px;
    }

    &__list {
      flex-direction: column;
    }
  }
}