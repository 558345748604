.mobile-menu{
  &__list{
    padding-bottom: 59px;
  }

  &__item{

  }

  &__link{
    font-size: 17px;
    color: #000000;
    line-height: 45px;
    text-decoration: none;
    display: flex;
    align-items: center;

    &_parent{

    }
  }

  &__child-container{
    display: none;

    &_active{
      display: block;
    }
  }

  &__child-item{
    padding-left: 38px;
  }

  &__child-link{
    font-size: 16px;
    color: rgba(0,0,0,0.56);
    line-height: 33px;
    text-decoration: none;
  }


  &__icon{
    display: inline-block;
    width: 33px;
    padding-bottom: 5px;
  }

  .header__city{
    padding-top: 59px;
  }
}