.flash-messages-block {
  .flash-list {
    display: block;

    li {
      display: block;
      font-size: 18px;

      .message {
        height: 60px;
        line-height: 60px;
        padding-left: 60px;
      }

      &.error {
        background-color: #FDEAEA;
        border-bottom: 1px solid #E3B7B7;

        .message {
          background: $t url('../images/flash/error-flash.png') 0 50% no-repeat;
          background-size: 40px 40px;
          color: #955F5F;
        }
      }

      &.success {
        background: rgba(0,0,0,0.73);
        padding: 20px 0;

        .message {
          background: $t url('../images/flash/success-flash.svg') 0 50% no-repeat;
          background-size: 40px 40px;
          font-family: $h-font;
          font-size: 22px;
          color: #FFFFFF;
        }
      }
    }
  }
}

@include respond-down('small') {
  .flash-messages-block {
    .flash-list {
      li {
        &.success {
          .message {
            font-size: 18px;
          }
        }
      }
    }
  }
}