.filter-block{
  &__form{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__field{
    flex: 1 1 0;
    padding: 0 15px;
    position: relative;
    margin-bottom: 10px;

    &::before{
      content: '';
      position: absolute;
      right: 32px;
      height: 56px;
      width: 12px;
      bottom: 0;
      background: $t url('../svg/arrow_down_blue.svg') center no-repeat;
      background-size: contain;
    }
    select{
      height: 56px;
      line-height: 56px;
      padding: 0 24px;

    }
    label{
      font-size: 14px;
      color: rgba(0,0,0,0.56);
      line-height: 24px;
      margin-bottom: 10px;
    }
  }

  &__toggle{
    display: none;
    width: 100%;
    text-transform: uppercase;
    height: 53px;
    line-height: 53px;
    border-bottom: 1px solid #D4DADF;
    border-top: 1px solid #D4DADF;
    font-size: 15px;
    color: black;
    letter-spacing: 0.83px;
    text-decoration: none;
    padding-left: 55px;
    position: relative;
    margin-bottom: 17px;

    &::before{
      content: '';
      height: 100%;
      width: 34px;
      background: $t url('../svg/settings.svg') center no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::after{
      content: '';
      position: absolute;
      right: 2px;
      height: 100%;
      width: 12px;
      background: $t url('../svg/arrow_up_red.svg') center no-repeat;
      background-size: contain;
      transition: .3s;
    }

    &_active{
      &::after{
        transform: rotate(180deg);
      }
    }
  }

  &__controll{
    margin-top: 40px;
    flex: 0 0 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  &__order-field{
    flex: 1 0 auto;
    display: flex;
    align-items: center;

    label{
      font-size: 15px;
      color: black;
      line-height: 24px;
      margin: 0;
      padding-right: 3px;
    }
    select{
      width: 180px;
      background-color: $t;
      border: none;
      padding: 0;
      height: auto;
      color: #5165D6;
    }
  }
  &__cleaner{
    flex: 0 0 auto;
    font-size: 15px;
    color: black;
    line-height: 24px;
    text-decoration: none;
    padding-left: 18px;
    display: block;
    position: relative;

    &::before{
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 12px;
      background: $t url('../svg/closer_small.svg') center no-repeat;
      background-size: contain;
    }
  }
}

@include respond('medium'){
  .filter-block{
    &__form{
      flex-wrap: wrap;
    }

    &__field{
      &_material, &_material_color{
        flex: 1 1 50%;
      }
      &_length, &_collar, &_type{
        flex: 1 1 33.33%;
      }
    }
  }
}

@include respond-down('small'){
  .filter-block{
    &__form {
      flex-wrap: wrap;
      margin: 0;
      &_hide{
        display: none;
      }
    }

    &__field{
      padding: 0;
      flex: 0 0 100%;
      label{
        margin-bottom: 3px;
      }
    }

    &__toggle{
      display: block;
    }

    &__controll{
      flex-wrap: wrap;
      margin-top: 23px;
      padding: 0;
    }

    &__order-field{
      order: 2;
      flex: 0 0 100%;

      select, label {
        font-size: 14px;
      }
    }

    &__cleaner{
      order: 1;
      flex: 0 0 100%;
      margin-bottom: 29px;
    }
  }
}