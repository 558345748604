.hero-info {
  &__title {

  }

  &__title-inner {
    &_blue {
      color: $primary-color;
    }
  }

  &__city {

  }

  &__description {
    font-size: 16px;
    color: rgba(0,0,0,0.56);
    letter-spacing: 0;
    line-height: 24/16*1em;
  }

  &__streak {
    display: inline-block;
    background-color: #fff;
    border-left: 4px solid $secondary-color;
  }

  &__date,
  &__code {
    display: block;
    font-family: $h-font;
    text-transform: uppercase;
    color: $primary-color;
    line-height: 1;
  }

  &__promo-code {
    font-weight: 500;
  }

  &__address {
    display: block;
  }

  &__form {
    .line-form {
      &__personal {
        color: rgba(0,0,0,0.35);

        a {
          color: rgba(0,0,0,0.35);
        }
      }
    }
  }
}

@include respond-up('large') {
  .hero-info {
    padding-right: 50px;

    &__title-inner {
      font-size: 35px;
      letter-spacing: 1/35*1em;
      line-height: 42/35*1em;
    }

    &__description {
      padding-top: 33px;
    }

    &__streak {
      max-width: 460px;
      margin-top: 45px;
      padding: 30px 40px 32px 34px;
    }

    &__date {
      padding-bottom: 4px;
      font-size: 22px;
      letter-spacing: 0.88/22*1em;
    }

    &__address {
      font-size: 20px;
      line-height: 24/20*1em;
    }

    &__code {
      padding-top: 16px;
      font-size: 18px;
      letter-spacing: 0.88/18*1em;
    }

    &__form {
      margin-top: 65px;
    }
  }
}

@include respond('medium') {
  .hero-info {
    &__title-inner {
      font-size: 27px;
      letter-spacing: 0.77/27*1em;
      line-height: 35/27*1em;
    }

    &__description {
      padding-top: 25px;
    }

    &__streak {
      max-width: 450px;
      margin-top: 36px;
      padding: 20px 60px 16px 20px;
    }

    &__form {
      margin-top: 40px;
      max-width: 600px;
    }

    &__code {
      padding-top: 12px;
      font-size: 18px;
      letter-spacing: 0.88/18*1em;
    }
  }
}

@include respond-down('medium') {
  .hero-info {
    &__date {
      padding-bottom: 5px;
    }
  }
}

@include respond-down('small') {
  .hero-info {
    &__title-inner {
      font-size: 21px;
      letter-spacing: 0.6/21*1em;
      line-height: 28/21*1em;
    }

    &__description {
      padding-top: 23px;
      font-size: 15px;
    }

    &__streak {
      width: 100%;
      max-width: 400px;
      margin-top: 35px;
      padding: 20px 20px 18px 18px;
    }

    &__form {
      margin-top: 28px;
    }

    &__code {
      padding-top: 12px;
      font-size: 14px;
      letter-spacing: 0.88/14*1em;
      line-height: 1.3;
    }
  }
}