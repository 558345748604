.product-card {
  position: relative;
  height: 100%;
  width: 100%;

  &__link {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #000;
    height: 100%;
  }

  &__name {
    display: block;
  }

  &__price {
    font-weight: bold;
    display: block;

    &_old {
      font-weight: 500;
      color: #707070;
      text-decoration: line-through;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    right: 0;
    color: #FFFFFF;
    text-transform: lowercase;
    padding: 2px 11px;
    font-size: 15px;
  }
}

@include respond-up('large') {
  .product-card {
    &__info {
      padding: 22px 30px 30px;
    }

    &__name {
      font-size: 16px;
      max-width: 209px;
    }

    &__price {
      padding-top: 11px;
      font-size: 17px;

      &_old {
        padding-top: 6px;
        font-size: 14px;
      }
    }
  }
}

@include respond ('medium') {
  .product-card {
    &__info {
      padding-left: 23px;
      padding-top: 19px;
      padding-bottom: 23px;
      max-width: 209px;
    }

    &__name {
      font-size: 15px;
      max-width: 209px;
    }

    &__price {
      padding-top: 11px;
      font-size: 16px;

      &_old {
        padding-top: 6px;
        font-size: 14px;
      }
    }
  }
}

@include respond-down ('small') {
  .product-card {
    &__info {
      padding-left: 15px;
      padding-top: 14px;
      padding-bottom: 14px;
      max-width: 129px;
    }

    &__name {
      font-size: 15px;
      max-width: 209px;
    }

    &__price {
      padding-top: 9px;
      font-size: 16px;

      &_old {
        padding-top: 6px;
        font-size: 14px;
      }
    }
  }
}