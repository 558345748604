.main-menu{
  display: flex;

  &__list{
    display: flex;
    &_collapsing{

    }
  }
  &__item{
    display: flex;
    align-items: center;
    position: relative;
    &:hover{
      .main-menu {
        &__child-container {
          display: block;
        }

        &__link{
          &_parent{
            &::before{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  &__link{
    font-size: 16px;
    text-decoration: none;
    padding: 0 13px;
    color: black;
    white-space: nowrap;
    height: 100%;
    line-height: 122px;

    &_parent{
      position: relative;
      padding-right: 31px;

      &::before{
        content: '';
        width: 9px;
        height: 100%;
        background: $t url("../svg/arrow_down_blue.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        right: 15px;
        top: 0;
        transition: .3s;
      }
    }
  }

  &__child-container{
    position: absolute;
    background-color: white;
    padding: 14px 0;
    top: 100%;
    left: -35px;
    box-shadow: 0 2px 37px 0 rgba(0,0,0,0.11);
    z-index: 100;
    display: none;

    &::before{
      content: '';
      border: 16px solid $t;
      border-bottom-color: white;
      position: absolute;
      top: -32px;
      left: 65px;
    }
  }

  &__child-item{
    height: 35px;
    &:hover{
      background-color: #F2F3F4;
    }
  }

  &__child-link{
    white-space: nowrap;
    text-decoration: none;
    font-size: 15px;
    line-height: 35px;
    padding: 0 31px 0 21px;
    color: black;
    height: 100%;
    display: block;
  }

  &__other-wrapper{
    flex: 0 0 auto;
    position: relative;

  }

  &__other-link{
    display: none;
    align-self: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    height: 100%;
    line-height: 122px;
  }
}

@include respond-down('medium') {
  .main-menu{
    &__list{
      &_collapsing{
        display: none;
      }
    }
    &__other-wrapper{
      &:hover{
        .main-menu{
          &__list{
            position: absolute;
            background-color: white;
            padding: 14px 0;
            top: 100%;
            right: -35px;
            box-shadow: 0 2px 37px 0 rgba(0,0,0,0.11);
            z-index: 100;
            display: block;

            &::before{
              content: '';
              border: 16px solid $t;
              border-bottom-color: white;
              position: absolute;
              top: -32px;
              right: 65px;
            }
          }
          &__item{
            &:hover{
              background-color: #F2F3F4;
            }
          }
          &__link{
            white-space: nowrap;
            text-decoration: none;
            font-size: 15px;
            padding: 0 31px 0 21px;
            color: black;
            line-height: 35px;
            display: block;
            &_parent{
              &::before{
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    &__other-link{
      display: block;
    }
  }
}