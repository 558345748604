.contacts-info {
  background-color: #FFFFFF;

  &__wrapper {
    display: flex;
  }

  &__caption {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-family: $h-font;
    text-transform: uppercase;
    /* Узнайте о ближайшей: */
    letter-spacing: 1px;
    line-height: 1.4;
  }

  &__text {
    color: rgba(0,0,0,0.56);
  }

  &_trade {
    background: $grey-color;
  }
}

@include respond-up('large') {
  .contacts-info {
    &__wrapper {
      padding: 120px 0 100px;
      align-items: center;
    }

    &__caption {
      flex: 0 0 70%;
    }

    &__name {
      font-size: 35px;
      max-width: 681px;
    }

    &__text {
      padding-top: 32px;
      font-size: 16px;
      max-width: 556px;
    }

    &__form {
      flex: 0 0 30%;
    }
  }
}

@include respond('medium') {
  .contacts-info {
    &__wrapper {
      padding: 62px 148px 75px 0;
      flex-direction: column;
    }

    &__caption {
      flex: 0 0 100%;
    }

    &__name {
      font-size: 27px;
    }

    &__text {
      padding-top: 26px;
      font-size: 16px;
    }

    &__form {
      margin-top: 45px;
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .contacts-info {
    &__wrapper {
      display: block;
      padding: 48px 0;
      flex-direction: column;
    }

    &__caption {
      flex: 0 0 100%;
    }

    &__name {
      font-size: 21px;
    }

    &__text {
      padding-top: 26px;
      font-size: 15px;
    }

    &__form {
      margin-top: 34px;
      flex: 0 0 100%;
    }
  }
}