.header{
  padding: 0 55px;
  background-color: white;
  opacity: 1;
  transition-duration: 1s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;



  &__top{
    height: 73px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E3E3E3;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .4s;
    transition-delay: 1s;
    transition-timing-function: ease-in-out;



  }

  &__bottom{
    height: 122px;
    display: flex;
    align-items: center;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .4s;
    transition-delay: .8s;
    transition-timing-function: ease-in-out;
  }

  &__city{
    padding-left: 30px;
    position: relative;
    flex: 1 0 auto;
    font-size: 16px;

    &::before{
      content: '';
      width: 15px;
      height: 100%;
      position: absolute;
      left: 0;
      top: calc(50% - 9px);
      background: $t url("../svg/pm_blue.svg") center no-repeat;
      background-size: contain;
    }
  }

  &__city-link {
    color: $primary-color;
  }

  &__phone{
    flex: 0 0 auto;
    margin: 0 47px 0 30px;
    font-weight: bold;
    font-size: 21px;
    color: #000000;
    letter-spacing: 0;
    text-decoration: none;
  }

  &__callback{
    flex: 0 0 auto;
    background-color: $t;
    padding: 0 32px 0 27px;
    height: 38px;
    line-height: 38px;
  }
  &__callback-phone{
    padding-right: 5px;
  }

  &__logo-wrapper{
    flex: 1 0 auto;
  }
  &__main-menu{
    flex: 0 0 auto;
    align-self: stretch;
  }

  &__mobile{
    display: none;
    align-items: stretch;
    justify-content: space-between;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 23px;
    background-color: white;
    z-index: 100;
    opacity: 1;
    transition-delay: .5s;
    transition-duration: .9s;
    transition-property: opacity, transform;


  }
  &__mobile-menu{
    display: none;
  }

  &.layout__header_index{
    &.auto-animated{
      opacity: 0;
      transition-property: none;
      transform: translateY(-100%);
      transition-delay: 0s;
    }

    .header{
      &__mobile{
        &.auto-animated{
          opacity: 0;
          transform: translateY(-100%);
          transition-property: none;
          transition-delay: 0s;
        }
      }
      &__top{
        &.auto-animated{
          opacity: 0;
          transform: translateY(-30%);
          transition-property: none;
          transition-delay: 0s;
        }
      }
      &__bottom{
        &.auto-animated{
          opacity: 0;
          transform: translateY(-20%);
          transition-property: none;
          transition-delay: 0s;
        }
      }
    }

  }
}

@include respond('medium'){
  .header{
    padding: 0 33px;

    &__callback{
      display: none;
    }
    &__phone{
      margin-right: 0;
    }
    &__logo-wrapper{
      .svg-icon{
        width: 226px;
      }
    }
  }
}

@include respond-down('small'){
  .header{
    height: 70px;

    &__top, &__bottom{
      display: none;
    }

    &__mobile{
      display: flex;
      .svg-icon{
        height: 100%;
      }
    }

    &__hamburger{
      display: block;
      height: 70px;
    }
    &__closer{
      height: 70px;
      display: none;
    }

    &__hamburger-link{
      flex: 0 0 31px;
      &_open{
        .header__hamburger{
          display: none;
        }
        .header__closer{
          display: block;
        }

      }
    }

    &__mobile-logo {
      height: 70px;
    }
    &__mobile-callback{
      flex: 0 0 31px;
      height: 70px;
      text-align: right;
    }
    &__mobile-menu{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      background-color: white;
      padding: 95px 26px 51px;
      z-index: 50;
      overflow: auto;

      &_open{
        display: block;
      }
    }
  }
}

