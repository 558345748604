.images-slider{
  &__container{
    width: 100%;
    height: 671px;
  }
  &__image-wrapper{
    height: 671px;
    width: 100%;
    position: relative;
  }
  &__image{
    width: 100%;
    height: 100%;
  }
  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__badge{
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    height: 25px;
    line-height: 25px;
    padding: 0 11px 0 14px;
    color: white;
    white-space: nowrap;
  }

  &__image-element{
    display: block;
    width: 500px;
    height: 100%;
    object-fit: cover;
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    };
  }

  &__thumbnails-container{
    height: 151px;
    padding: 22px 66px;
    background-color: white;
    position: relative;
  }

  &__thumbnails{
    //display: flex;
  }

  &__thumbnails-item{
    height: 107px;
    &[aria-selected="true"]{
      .images-slider__thumbnails-image{
        border: 3px solid #206FDE;
      }
    }
  }

  &__thumbnails-image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: block;
    cursor: pointer;
    border: 3px solid $t;

  }

  &__arrow{
    position: absolute;
    top: calc(50% - 10px);
    height: 21px;
    width: 21px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &_prev{
      background-image: url("../svg/arrow_left_red.svg");
      left: 30px;
    }
    &_next{
      background-image: url("../svg/arrow_right_red.svg");
      right: 30px;
    }
  }
}

@include respond-up('large') {
  .images-slider {
    &__image-link {
      cursor: url('../images/loupe.svg'), pointer;
    }

    &__img {
      display: block;
      max-height: 671px;
    }

    &__image {
      position: relative;
    }

    &__preview {
      right: -600px;
    }
  }
}

@include respond('medium'){
  .images-slider{
    display: flex;
    max-width: 700px;

    &__image-wrapper{
      flex: 1 1 auto;
      order: 2;
    }

    &__img {
      max-height: 671px;
    }

    &__thumbnails-container{
      display: inline-block;
      height: 671px;
      padding: 71px 51px;
      flex: 0 0 186px;
      order: 1;
    }
    &__thumbnails-item{
      height: 112px;
    }
    &__thumbnails{
      height: 522px;
    }

    &__arrow{
      left: calc(50% - 10px);
      &_prev{
        top: 36px;
        background-image: url("../svg/arrow_up_red.svg");
      }
      &_next{
        background-image: url("../svg/arrow_down_red.svg");
        top: unset;
        bottom: 36px;
      }
    }
  }
}

@include respond-down('small'){
  .images-slider{
    &__image-wrapper{
      height: auto;
      width: 100%;
    }
    &__container{
      width: 100%;
      height: auto;
    }
    &__image{
      width: 100%;
      height: auto;
    }
    &__image-element{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    &__thumbnails-wrapper{
      flex: 0 0 100%;
    }
    &__thumbnails-container{
      height: auto;
      padding: 10px 49px;
      display: flex;
      align-items: center;
    }
    &__thumbnails-item{
      height: auto;
    }
    &__thumbnails-image{
      padding-bottom: 132%;
    }
    &__thumbnails{
      flex: 0 0 100%;
    }

    &__arrow{
      height: 18px;
      width: 13px;
      top: calc(50% - 9px);

      &_prev{
        left: 21px;
      }
      &_next{
        right: 21px;
      }
    }

  }
}