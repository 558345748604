h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
  text-transform: uppercase;
  line-height: 1.2;
}

$h-size: 35px;
@for $i from 1 to 6 {
  h#{$i}, .h#{$i} {
    font-size: $h-size;
    letter-spacing: (1/35)*1em;
    $h-size: $h-size - 2px;
  }
}

@include respond('medium') {
  $h-size: 27px;
  @for $i from 1 to 6 {
    h#{$i}, .h#{$i} {
      font-size: $h-size;
      $h-size: $h-size - 2px;
    }
  }
}

@include respond-down('small') {
  $h-size: 21px;
  @for $i from 1 to 6 {
    h#{$i}, .h#{$i} {
      font-size: $h-size;
      $h-size: $h-size - 2px;
    }
  }
}