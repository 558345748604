.buy {
  background-color: #FFFFFF;
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: .8s;

  &.need-animation{
    opacity: 0;
    transform: translateY(40px);
    transition-property: none;

    .buy__title{
      opacity: 0;
      transform: translateY(10px);
      transition-property: none;
    }
  }

  &__title{
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .6s;
    transition-delay: .6s;

  }
  &__steps {
    display: flex;
    justify-content: space-between;

  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 0 0 33.333%;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .6s;


    &:nth-child(1),&:nth-child(2) {
      &::before{
        content: '';
        display: block;
        height: 3px;
        background-color: $grey-color;
        position: absolute;
        top: 80px;
      }
    }
  }

  &__button {
    background-color: $secondary-color;
    width: 251px;
    padding: 11px 24px;
    text-align: center;
    border-radius: 100px;
    font-size: 16px;
    color: #FFFFFF;
  }

  &__link {
    display: flex;
    align-items: center;
    padding-top: 21px;
    text-decoration: none;
    color: #5165D6;
    border-bottom: 1px solid rgba(81,101,214,0.25);
    padding-bottom: 6px;
  }

  &__link-icon {
    display: flex;
    align-items: center;
    height: 20px;
    padding-right: 12px;
  }

  &__link-text {
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    color: #5165D6;
    letter-spacing: 0.94px;
  }
}

@include respond-up('large') {
  .buy {
    padding-bottom: 130px;
    background: {
      image: url('../images/lace.png');
      position: top right;
      repeat: no-repeat;
      size: 338px 320px;
    };


    &__title {
      padding-top: 139px;
      padding-bottom: 85px;
    }

    &__image {
      padding-bottom: 34px;
    }

    &__wrapper {
      overflow: hidden;
    }

    &__steps {
      margin: 0 -110px;
      &.need-animation{
        .buy__step{
          opacity: 0;
          transform: translateX(-10px);
          transition-property: none;
          &::before {
            width: 0;
            transition-property: none;
          }
        }
      }
    }

    &__step {
      padding: 0 110px;
      @for $i from 1 through 3 {
        &:nth-child(#{$i}){
          transition-delay: calc(#{$i} * 0.8s + .1s);

          &::before {
            left: 350px;
            width: 51%;
            transition-property: width;
            transition-duration: .7s;
            transition-delay: calc(#{$i} * .9s);
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .buy {
    padding-bottom: 75px;
    &__title {
      padding-top: 69px;
      padding-bottom: 62px;
    }

    &__image {
      padding-bottom: 34px;
    }

    &__steps {
      padding-left: 30px;
      margin: 0 -45px;
      opacity: 1;
      transition-property: opacity, transform;
      transition-duration: .6s;
      transition-delay: .6s;
      &.need-animation{
        opacity: 0;
        transition-property: none;
        transform: translateY(20px);
      }
    }

    &__step {
      padding: 0 45px;
      &:nth-child(1),&:nth-child(2) {
        &::before {
          right: -16%;
          width: 35%;
        }
      }
    }

    &__container {
      overflow: hidden;
      margin: 0 -30px;
    }

    &__wrapper {
      position: relative;
      padding-bottom: 40px;
      overflow-y: hidden;
      overflow-x: scroll;
      margin-bottom: -40px;
    }
  }
}

@include respond-down('small') {
  .buy {
    padding-bottom: 50px;
    &__title {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    &__image {
      padding-bottom: 21px;
    }

    &__steps {
      margin: 0 -5px;
      opacity: 1;
      transition-property: opacity, transform;
      transition-duration: .6s;
      transition-delay: .6s;
      &.need-animation{
        opacity: 0;
        transition-property: none;
        transform: translateY(20px);
      }
    }

    &__step {
      padding: 0 29px;
      &:nth-child(1),&:nth-child(2) {
        &::before {
          left: 270px;
          width: 69px;
        }

      }
    }

    &__container {
      overflow: hidden;
      margin: 0 -20px;
    }

    &__wrapper {
      position: relative;
      padding-bottom: 40px;
      overflow-y: hidden;
      overflow-x: scroll;
      margin-bottom: -40px;
    }

    &__button {
      font-size: 15px;
    }

    &__link-text {
      font-size: 15px;
    }
  }
}