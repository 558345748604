.product-page{
  padding: 91px 0;
  &__content-header{
    display: none;
  }

  &__product-wrapper{
    display: flex;
    justify-content: space-between;
  }

  &__slider-block{
    flex:0 0 41.66667%;
    margin-left: 20px;

  }

  &__info-wrapper{
    flex: 0 1 50%;
    padding-left: 20px;
    padding-top: 43px;
    position: relative;

    .magnifier-preview {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;

      img {
        height: auto !important;
        max-width: none;
      }
    }
  }

  &__name{
    font-size: 30px;
    color: black;
    letter-spacing: 0.86px;
    line-height: 40px;
    font-family: "Geometria";
    text-transform: uppercase;
  }

  &__price{
    font-weight: bold;
    font-size: 25px;
    color: black;
    letter-spacing: 1.56px;
    padding: 51px 0 61px;

    &_old {
      font-weight: 500;
      padding-left: 10px;
      color: #707070;
      font-size: 22px;
      text-decoration: line-through;
    }
  }

  &__button{
    width: 248px;
  }

  &__request{
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    color: #5165D6;
    letter-spacing: 1px;
    text-decoration: none;
    border-bottom: 1px solid rgba(81,101,214,0.25);
    padding-bottom: 7px;
    padding-left: 26px;
    position: relative;
    margin-left: 53px;
    &::before{
      content: '';
      width: 11px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 2px;
      background: $t url('../svg/phone_black.svg') center no-repeat;
      background-size: contain;
    }
  }

  &__info-block{
    margin-top: 69px;
    background-color: white;
  }
  &__info-tab{
    padding: 0 36px;

    &:not(first-child){
      border-bottom: 1px solid #E3E3E3;
    }

    &_active{
      .product-page{
        &__info-link{
          &::before{
            background-image: url('../svg/minus.svg');
          }
        }
        &__info-content{
          display: block;
        }
      }
    }
  }

  &__info-link{
    display: block;
    line-height: 60px;
    height: 60px;
    font-size: 16px;
    color: black;
    letter-spacing: 0;
    text-decoration: none;
    width: 100%;
    position: relative;

    &::before{
      content: '';
      position: absolute;
      height: 22px;
      width: 22px;
      right: 0;
      top: 19px;
      background: $t url('../svg/plus.svg') center no-repeat;
      background-size: contain;
    }
  }

  &__info-content{
    padding-bottom: 34px;
    padding-right: 72px;
    display: none;

  }

  &__character-row{
    display: flex;
    padding-bottom: 6px;
  }
  &__character-name{
    flex: 0 0 192px;
    font-size: 16px;
    color: rgba(0,0,0,0.56);
    line-height: 24px;
  }
  &__character-value{
    flex: 1 1 auto;
    font-size: 16px;
    color: black;
    line-height: 24px;
  }

}

@include respond-down('medium'){
  .product-page{
    &__content-header{
      display: block;
    }
    &__name, &__breadcrumbs{
      display: none;
    }
    &__product-wrapper{
      flex-wrap: wrap;
    }
    &__slider-block{
      flex:0 0 100%;
      margin-left: 0px;

    }
    &__info-wrapper{
      flex: 0 0 100%;
      padding-left: 0px;
      padding-top: 0px;
    }

    &__info-block{
      margin-top: 43px;
    }
  }
}

@include respond('medium'){
  .product-page{
    padding: 0 0 40px;

    &__price{
      padding: 47px 0 33px;

      &_old {
        padding-left: 10px;
      }
    }
  }
}

@include respond-down('medium'){
  .product-page{
    &__info-wrapper {
      .magnifier-preview {
        display: none;
      }
    }
  }
}

@include respond-down('small'){
  .product-page{
    padding: 0 0 40px;
    &__price{
      padding: 38px 0 27px;
      text-align: center;

      &_old {
        padding: 8px 0 0 0;
        display: block;
      }
    }

    &__button{
      width: 239px;
      margin: auto;
      display: block;
    }

    &__request{
      margin: 27px auto 0;
      width: 239px;
      display: block;
    }
    &__info-tab{
      padding: 0 20px;
    }

    &__character-name{
      flex: 0 0 150px;
    }

    &__info-content{
      padding-right: 20px;
    }
  }
}

@include respond('x-small'){
  .product-page{
    &__character-name{
      flex: 0 0 125px;
      font-size: 14px;
    }
    &__character-value{
      font-size: 14px;
    }
  }
}