.show-card {
  &__link {
    background-color: $grey-color;
    display: flex;
    height: 100%;
    text-decoration: none;
  }

  &__image {
    width: 100%;
    .flexible-image {
      object-fit: cover;
      height: 100%;
      &__container {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__line {
    display: block;
    width: 34px;
    height: 4px;
    background-color: $secondary-color;
  }

  &__name {
    font-family: $h-font;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.51px;
    line-height: 1.35;
  }

  &__arrow {
    display: none;
  }
}

@include respond-up('large') {
  .show-card {
    padding: 0 20px;
    flex: 0 0 33.333%;
    &__link {
      flex-direction: column;
    }

    &__container {
      padding-bottom: 34px;
    }

    &__line {
      margin: 25px 0 17px;
    }

    &__name {
      max-width: 254px;
      text-align: center;
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .show-card {
    padding: 0 16px;
    flex: 0 0 33.333%;
    &__link {
      flex-direction: column;
    }

    &__container {
      padding-bottom: 32px;
    }

    &__line {
      margin: 25px 0 11px;
    }

    &__name {
      max-width: 180px;
      padding: 0 16px;
      text-align: center;
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .show-card {
    padding-bottom: 20px;
    flex: 0 0 100%;
    &__link {
      flex-direction: row;
      height: 230px;
    }

    &__image {
      flex: 0 0 155px;

      .flexible-image {
        height: 100%;

        &__container {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__container {
      padding: 28px 5px 21px 21px;
      align-items: stretch;
    }

    &__line {
      margin-bottom: 18px;
    }

    &__name {
      max-width: 180px;
      text-align: left;
      font-size: 15px;
      word-break: break-word;
    }

    &__arrow {
      display: block;
      margin-top: auto;
    }
  }
}

@include respond-down('x-small') {
  .show-card {
    &__name {
      font-size: 12px;
    }
  }
}