.about-us {
  &__head {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    display: flex;
    align-items: center;
    align-self: flex-start;
    text-decoration: none;
    border-bottom: 1px solid rgba(81,101,214,0.25);
  }

  &__more {
    color: $primary-color;
    padding-left: 8px;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
  }
}

@include respond-up('large') {
  .about-us {
    &__head {
      padding-bottom: 110px;
    }

    &__container {
      flex: 0 0 50%;

      &_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__title {
      padding-bottom: 60px;
    }

    &__text {
      position: relative;
      padding-right: 190px;
      margin-bottom: 70px;

      &::before {
        content: '';
        position: absolute;
        bottom: -70px;
        width: 80px;
        border-bottom: 3px solid $secondary-color;
      }

      p {
        font-size: 24px;
      }
    }

    &__description {
      padding-bottom: 20px;
    }

    &__values {
      padding-bottom: 150px;
    }
  }
}

@include respond-up('medium') {
  .about-us {
    &__link {
      padding-bottom: 6px;
    }
  }
}

@include respond('medium') {
  .about-us {
    &__head {
      padding-bottom: 60px;
    }

    &__title {
      padding-bottom: 34px;
    }

    &__text {
      padding-bottom: 22px;

      p {
        font-size: 22px;
      }
    }

    &__description {
      padding-bottom: 40px;
    }

    &__values {
      padding-bottom: 80px;
    }
  }
}

@include respond-down('medium') {
  .about-us {
    &__link {
      padding-bottom: 6px;
    }

    &__container {
      flex: 0 0 100%;

      &_right {
        display: flex;
        flex-direction: column;
      }
    }

    &__text {
      padding-bottom: 22px;
    }
  }
}

@include respond-down('small') {
  .about-us {
    &__head {
      padding-bottom: 40px;
    }

    &__title {
      padding-bottom: 28px;
    }

    &__text {
      p {
        font-size: 18px;
      }
    }

    &__description {
      padding-bottom: 25px;
    }

    &__link {
      padding-bottom: 4px;
    }

    &__values {
      padding-bottom: 50px;
    }
  }
}