.request-form {
  position: relative;

  &__data {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__success {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.73);
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    z-index: 150;
    display: flex;
    align-items: center;
    transform: translate3d(0,40px,0);
  }

  &__success-block {
    display: flex;
  }

  &__success-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__success-icon {
    display: none;
    &_big {
      display: block;
    }
    &_small {
      display: none;
    }
  }

  &__success-title {
    font-weight: 400;
    color: #FFFFFF;
    font-family: $h-font;
    letter-spacing: 0.54px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__success-description {
    color: rgba(255,255,255,0.55);
    line-height: 24px;
  }

  &.success {
    .request-form {
      &__data {
        opacity: 0.05;
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0,0,0);
      }
    }
  }
}

@include respond-up('large') {
  .request-form {
    &__success {
      height: 101px;
    }

    &__success-icon {
      &_big {
        display: block;
      }
      &_small {
        display: none;
      }
    }

    &__success-block {
      margin: 0 253px;
    }

    &__success-container {
      margin-left: 30px;
    }

    &__success-title {
      font-size: 19px;
    }

    &__success-description {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .request-form {
    &__success {
      height: 101px;
    }

    &__success-icon {
      &_big {
        display: block;
      }
      &_small {
        display: none;
      }
    }

    &__success-block {
      margin: 0 80px;
    }

    &__success-container {
      margin-left: 30px;
    }

    &__success-title {
      font-size: 18px;
    }

    &__success-description {
      font-size: 17px;
    }
  }
}

@include respond-down('small') {
  .request-form {
    &__success {
      height: 176px;
    }

    &__success-icon {
      &_small {
        display: block;
      }
      &_big {
        display: none;
      }
    }

    &__success-block {
      margin: 0 20px;
      flex-direction: column;
    }

    &__success-container {
      margin-top: 14px;
    }

    &__success-title {
      font-size: 16px;
    }

    &__success-description {
      font-size: 16px;
    }
  }
}