.offer {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;

    &_offsite {
      right: -50px;
    }
  }

  &__title-link {
    color: $primary-color;
    text-decoration: none;
  }
  &__title{
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .5s;
    transition-delay: 1.7s;
    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateY(10px);
      transition-delay: 0s;
    }
  }

  &__content {
    position: relative;
    z-index: 20;
  }

  &__streak {
    display: block;
    background-color: $grey-color;
    border-left: 4px solid $secondary-color;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .5s;
    transition-delay: 2s;
    text-decoration: none;
    color: inherit;

    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateY(20px);
      transition-delay: 0s;
    }
  }

  &__description {
    color: rgba(0,0,0,0.56);
    letter-spacing: 0;
    line-height: 24px;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .5s;
    transition-delay: 1.8s;
    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateY(15px);
      transition-delay: 0s;
    }
  }

  &__date {
    display: block;
    font-family: $h-font;
    color: $primary-color;
    text-transform: uppercase;
  }

  &__address {
    display: block;
    font-size: 14px;
  }

  &__pagination {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__form-wrapper {
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .6s;
    transition-delay: 2s;
    &.auto-animated{
      transition-property: none;
      opacity: 0;
      transform: translateY(15px);
      transition-delay: 0s;

    }
  }
}

@include respond-up('large') {
  .offer {
    &__wrapper {
      height: 640px;
    }

    &__image {
      left: -285px;

      &_offsite {
        left: 50px;
        background-position: 100% 100%;
      }
    }

    &__container {
      margin-left: 516px;
      padding-top: 128px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_offsite {
        margin-left: 100px;
        max-width: 550px;
      }
    }

    &__description {
      max-width: 571px;
      margin-top: 33px;
      margin-bottom: 93px;

      &_offsite {
        margin: 30px 0 50px;
      }
    }

    &__streak {
      padding: 30px 40px 30px 34px;
      min-width: 350px;
    }

    &__date {
      font-size: 22px;
      padding-bottom: 13px;
      font-family: $h-font;
    }

    &__address {
      font-size: 20px;
    }
  }

  .offer__container_no-show {
    .offer {
      &__title {
        max-width: 589px;
      }

      &__description {
        max-width: 468px;
      }

      &__form-wrapper {

      }
    }
  }
}

@include respond('medium') {
  .offer {

    &__image {
      left: -380px;

      &_offsite {
        left: 100px;
        background-position: 100% 100%;
      }
    }

    &__wrapper {
      height: 590px;
    }

    &__container {
      margin-left: 315px;
      padding-top: 74px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_offsite {
        margin-left: 34px;
        max-width: 420px;
      }
    }

    &__description {
      margin-top: 30px;
      margin-bottom: 60px;
      font-size: 16px;

      &_offsite {
        margin: 15px 0 30px;
      }
    }

    &__streak {
      padding: 26px 40px 26px 25px;
    }

    &__date {
      font-size: 17px;
      letter-spacing: 0.68px;
      padding-bottom: 10px;
      font-family: $h-font;
    }

    &__address {
      font-size: 16px;
    }
  }

  .offer__container_no-show {
    .offer {

      &__description {
        margin: 33px 0 51px;
      }

      &__form-wrapper {

      }
    }
  }
}

@include respond-down('small') {
  .offer {
    background-image: none !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__image-container {
      height: 275px;
      position: relative;
      overflow: hidden;
      margin: 0 -50px;
    }

    &__image {
      background-position: center;
      width: 100%;
    }

    &__image-wrapper {
      height: 100%;
      position: relative;
      margin: 0 -30px;
    }

    &__container {
      margin-top: 29px;
      padding-bottom: 54px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_offsite {
        margin: 40px 30px 0;
      }
    }

    &__content {
      position: relative;
    }

    &__description {
      margin-top: 23px;
      margin-bottom: 35px;
      font-size: 15px;
    }

    &__streak {
      padding: 21px 30px 18px 19px;
      flex: 0 0 100%;
      background-color: #FFFFFF;
    }

    &__date {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: $h-font;
    }

    &__address {
      font-size: 14px;
    }

    &__pagination {
      z-index: 40;
      bottom: -22px;
    }

    &__form-wrapper {
      width: 100%;
    }
  }

  .offer__container_no-show {
    .offer {
      &__description {
        margin: 23px 0 28px;
      }
    }
  }

  .offer_offsite {
    .offer {
      &__image-container {
        margin: 0;
      }
    }
  }
}