.socials{
  display: flex;

  .socials__item{
    width: 34px;
    height: 27px;
    margin: 0 3px;
  }

  .socials__link{
    width: 100%;
    height: 100%;
    display: block;
    background: no-repeat center;

    &_vk{
      border: 1px solid rgba(80, 114, 153, 0.38);
      background-image: url("../svg/vk.svg");
    }

    &_ok{
      border: 1px solid rgba(238, 130, 8, 0.38);
      background-image: url("../svg/ok.svg");
    }

    &_inst{
      border: 1px solid rgba(212, 43, 86, 0.38);
      background-image: url("../svg/inst.svg");
    }

    &_yt {
      border: 1px solid rgba(255, 0, 0, 0.38);
      background-image: url("../svg/youtube.svg");
    }
  }
}